/* eslint-disable no-unused-vars */ // !!!!!!!!!!!!!!!
// ### DOCUMENTATION ############################################################

// ### IMPORTS ##################################################################

import React, { useState, useEffect } from "react"
import { useAppSelector, useAppDispatch } from "store/store"
import styled from "styled-components"
// import { setMesExploitations } from "store/dataReducer"
import { setGlobal } from "store/dataReducer"
import { ZErrorInterface, ZError } from "libs/zError"
import {
    ZPage,
    ZPageHeader,
    ZPageContent,
    ZPageSection,
    // ZPageSectionHelp,
    ZPageSectionTodo,
} from "libs/zPage"
import { apiFetchGlobal } from "utils/api"
import ExploitationUsersList from "features/exploitation/mesExploitations/ExploitationUsersList"
import ExploitationButtonCreate from "features/exploitation/crud/ExploitationButtonCreate"
import ExploitationForm from "features/exploitation/crud/ExploitationForm"
// import ExploitationsSearch from "features/exploitation/search/ExploitationsSearch"
// import Hcctm from "features/Hcctm"
import SecteurNavlinkAndFlag from "features/secteur/SecteurNavlinkAndFlag"

// ### TYPES ####################################################################

// ### FUNCTIONS ################################################################

// ### COMPONENT ################################################################

export default function UserInfos({ userId }: { userId: number }) {
    const dispatch = useAppDispatch()
    const { gExploitations, gExploitationUsers, gSecteurs, gUsers } =
        useAppSelector((state) => state.data)

    const user = gUsers?.find((u) => u.id === userId)

    if (!user) {
        return <>User not find</>
    }

    return (
        <StyledC>
            <div className="">
                <dl>
                    <dt>username : </dt>
                    <dd>{user.username}</dd>

                    <dt>email : </dt>
                    <dd>{user.email}</dd>

                    <dt>nom : </dt>
                    <dd>{user.nom}</dd>

                    <dt>telephones : </dt>
                    <dd>xxx</dd>

                    <dt>roles : </dt>
                    <dd>
                        {user.roles?.map((rol) => (
                            <li key={"role" + rol}>{rol}</li>
                        ))}
                    </dd>

                    <dt>secteurs :</dt>
                    <dd>
                        {user.secteurs?.map((sec) => (
                            <SecteurNavlinkAndFlag
                                id={sec.id}
                                asNavLink
                                key={"user-sec" + sec.id}
                            />
                        ))}
                    </dd>
                </dl>
            </div>
        </StyledC>
    )
}

// ### STYLES ###################################################################

// ### STYLES ###################################################################

const StyledC = styled.div`
    dl {
        display: grid;
        grid-template-columns: max-content auto;
    }

    dt {
        grid-column-start: 1;
        padding-right: 5px;
        font-weight: inherit;
        text-align: right;
    }

    dd {
        grid-column-start: 2;
        font-weight: bold;
    }
`
