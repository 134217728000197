/* eslint-disable import/prefer-default-export */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
// import styled from 'styled-components'

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export const theme = {
    colors: {
        c1l5: "#F1FFEB",
        c1l4: "#E3FFD7",
        c1l3: "#BAFE9A",
        c1l2: "#4EF202",
        c1l1: "#34A202",

        c1: "#287F01",

        c1d1: "#216501",
        c1d2: "#1A5101",
        c1d3: "#143D01",
        c1d4: "#0D2801",
        c1d5: "#061401",

        danger: "#AA0000",
    },
}
