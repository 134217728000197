// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import { useAppSelector, useAppDispatch } from "store/store"
import ZModal from "libs/ZModal"
import { ZForm } from "libs/zForm"
import {
    setUserForm,
    UserCreateSuccess,
    UserUpdateSuccess,
    UserDeleteSuccess,
} from "store/dataReducer"
import { User } from "types"

import { zApiUserCreate, zApiUserUpdate, zApiUserDelete } from "utils/api"

import UserFormInner from "./UserFormInner"

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

// eslint-disable-next-line no-unused-vars
export default function UserForm() {
    const dispatch = useAppDispatch()
    const { userForm } = useAppSelector((state) => state.data)

    const customValidate = (values: User) => {
        let errors = {}

        if (!values.email) {
            errors = {
                ...errors,
                email: "L'email ne peut être vide.",
            }
        }
        return errors
    }

    if (!userForm) {
        return null
    }

    return (
        <ZModal
            isShown
            hide={() => null}
            headerText="aaaaaaaaa"
            // styles={{ maxWidth: "460px" }}
        >
            <ZForm
                initialData={userForm}
                customValidate={customValidate}
                closeForm={() => dispatch(setUserForm(null))}
                // *** Create:
                createTitle="Ajouter un utilisateur"
                createFunction={zApiUserCreate}
                createSuccessFunction={(result: any) =>
                    dispatch(UserCreateSuccess(result))
                }
                // *** Update:
                updateTitle={`Modifier l'utilisateur ${userForm?.email}`}
                updateFunction={zApiUserUpdate}
                updateSuccessFunction={(result: any) =>
                    dispatch(UserUpdateSuccess(result))
                }
                // *** Delete:
                deleteTitle={`Supprimer l'utilisateur ${userForm?.email}`}
                deleteConfirmText=""
                deleteFunction={zApiUserDelete}
                deleteSuccessFunction={(_result: any) =>
                    dispatch(UserDeleteSuccess(userForm.id))
                }
            >
                <UserFormInner />
            </ZForm>
        </ZModal>
    )
}
