/* eslint-disable react/no-unstable-nested-components */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import styled from "styled-components"
import { theme } from "styles/theme"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

type Props = {
    children?: React.ReactNode
    className?: String | null
}

ZPageSection.defaultProps = {
    children: null,
    className: "",
}

export default function ZPageSection({ children, className }: Props) {
    return (
        <StyledSectionContainer className={"zPageSection " + className}>
            <section>{children}</section>
        </StyledSectionContainer>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS

const StyledSectionContainer = styled.div`
    padding: 10px;
    margin-bottom: 0px;

    section {
        background-color: white;
        padding: 10px;
        border: 1px solid ${theme.colors.c1};

        .section-header {
            h2 {
                font-size: 1.4em;
                color: ${theme.colors.c1};
            }
            // margin-bottom: 50px;
        }
    }
`
