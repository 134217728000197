// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import { FormContext } from "./ZForm"
import ZFieldText from "./ZFieldText"
import ZFieldCheckbox from "./ZFieldCheckbox"
import ZFieldSelect from "./ZFieldSelect"
import ZFieldNumber from "./ZFieldNumber"
// import styled from "styled-components"

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

interface ISelectOption {
    value: any
    text: string
}

type Props = {
    name: string
    label: string
    type: string //     text, checkbox, number, select, date, time
    hint?: React.ReactNode | null
    autoFocus?: boolean
    fieldClassName?: string | null
    inputClassName?: string | null
    selectOptions?: ISelectOption[] | null
    step?: number | undefined
    fieldStyle?: string | undefined // "SWITCH" |"BS_FLOATING_LABEL" | "SIMPLE"
    // requiered // todo
    customHandleInputChange?: (_xx: any) => void // !! unused ??
    customValue?: any // !! unused ??
}

ZField.defaultProps = {
    hint: null,
    autoFocus: false,
    fieldClassName: null,
    inputClassName: null,
    selectOptions: null,
    step: undefined,
    fieldStyle: undefined,
    customHandleInputChange: null,
    customValue: null,
}

export default function ZField({
    name,
    label,
    type,
    hint,
    autoFocus,
    fieldClassName,
    inputClassName,
    selectOptions,
    step,
    fieldStyle,
    customHandleInputChange,
    customValue,
}: Props) {
    return (
        <FormContext.Consumer>
            {({ formData, handleInputChange, formErrors }) => (
                <>
                    {type === "checkbox" && (
                        <ZFieldCheckbox
                            label={label}
                            inputClassName={inputClassName + " "}
                            fieldClassName={fieldClassName + " "}
                            autoFocus={autoFocus}
                            hint={hint}
                            // type={type}
                            name={name}
                            value={
                                customValue ||
                                formData[name as keyof typeof formData]
                            }
                            handleInputChange={
                                customHandleInputChange || handleInputChange
                            }
                            error={formErrors[name as keyof typeof formErrors]}
                            fieldStyle={
                                fieldStyle === "SWITCH" ? "SWITCH" : "CB"
                            }
                        />
                    )}
                    {type === "select" && (
                        <ZFieldSelect
                            label={label}
                            inputClassName={inputClassName + " "}
                            fieldClassName={fieldClassName + " "}
                            autoFocus={autoFocus}
                            hint={hint}
                            // type={type}
                            name={name}
                            value={formData[name as keyof typeof formData]}
                            handleInputChange={handleInputChange}
                            error={formErrors[name as keyof typeof formErrors]}
                            selectOptions={selectOptions || []}
                            fieldStyle={
                                fieldStyle === "SIMPLE"
                                    ? "SIMPLE"
                                    : "BS_FLOATING_LABEL"
                            } //  "BS_FLOATING_LABEL" | "SIMPLE"
                        />
                    )}
                    {type === "number" && (
                        <ZFieldNumber
                            label={label}
                            inputClassName={inputClassName + " "}
                            fieldClassName={fieldClassName + " "}
                            autoFocus={autoFocus}
                            hint={hint}
                            name={name}
                            value={formData[name as keyof typeof formData]}
                            handleInputChange={handleInputChange}
                            error={formErrors[name as keyof typeof formErrors]}
                            fieldStyle={
                                fieldStyle === "SIMPLE"
                                    ? "SIMPLE"
                                    : "BS_FLOATING_LABEL"
                            } //  "BS_FLOATING_LABEL" | "SIMPLE"
                            step={step}
                        />
                    )}
                    {type !== "checkbox" &&
                        type !== "select" &&
                        type !== "number" && (
                            <ZFieldText
                                label={label}
                                inputClassName={inputClassName + " "}
                                fieldClassName={fieldClassName + " "}
                                autoFocus={autoFocus}
                                hint={hint}
                                type={type}
                                name={name}
                                value={formData[name as keyof typeof formData]}
                                handleInputChange={handleInputChange}
                                error={
                                    formErrors[name as keyof typeof formErrors]
                                }
                                fieldStyle={
                                    fieldStyle === "SIMPLE"
                                        ? "SIMPLE"
                                        : "BS_FLOATING_LABEL"
                                }
                            />
                        )}
                </>
            )}
        </FormContext.Consumer>
    )
}
