// ### DOCUMENTATION ############################################################

// ### IMPORTS ##################################################################

import { ZErrorInterface } from "libs/zError"

// ### TYPES ####################################################################

// ### FUNCTIONS ################################################################

export default function errorManager(reponse: any) {
    //  console.log("missingFields", missingFields)
    //  console.log("reponse", reponse)
    let zError: ZErrorInterface

    if (reponse.status && reponse.detail) {
        zError = {
            status: reponse.status,
            detail: reponse.detail,
        }
        console.log("❌ errorManager - withStatusAndDetail: ", zError)
    } else if (reponse.message) {
        zError = {
            detail: reponse.message,
            devMessage: reponse.message,
        }
        console.log("❌ errorManager - apixError: ", zError)
    } else if (reponse.apiError) {
        zError = {
            userMessage: reponse.apiError,
            devMessage: reponse.apiError,
        }
        console.log("❌ errorManager - apiError: ", zError)
    } else if (reponse.error) {
        zError = {
            userMessage: reponse.error,
            devMessage: reponse.error,
        }
        console.log("❌ errorManager - apiError: ", zError)
    } else if (reponse.apixError) {
        zError = {
            userMessage: reponse.apixError,
            devMessage: reponse.apixError,
        }
        console.log("❌ errorManager - apixError: ", zError)
    } else {
        console.log("Erreur Inconnue - Reponse:", reponse)
        zError = {
            userMessage: "Erreur Inconnue",
        }
    }
    //  console.log("zError befor return ", zError)
    return zError
}
