/* eslint-disable no-unused-vars */ // !!!!!!!!!!!!!!!
// ### DOCUMENTATION ############################################################

// ### IMPORTS ##################################################################

import React, { useState, useEffect } from "react"
import { useAppSelector, useAppDispatch } from "store/store"
import { ZErrorInterface, ZError } from "libs/zError"
import {
    ZPage,
    ZPageHeader,
    ZPageContent,
    ZPageSection,
    ZPageSectionHelp,
    ZPageSectionTodo,
} from "libs/zPage"
import Loading from "ui/Loading"
import { IcoSearch } from "libs/zIcones"
import { globalFetch } from "utils/api"
import ExploitationUsersList from "features/exploitation/mesExploitations/ExploitationUsersList"
import ConnectedUserInfos from "features/users/UserInfos"
import Questions from "features/Questions"
// import Hcctm from "features/Hcctm"

// ### TYPES ####################################################################

// ### FUNCTIONS ################################################################

// ### COMPONENT ################################################################

export default function PageHome() {
    const { gExploitations, gExploitationUsers, gSecteurs, gConnectedUserId } =
        useAppSelector((state) => state.data)
    const dispatch = useAppDispatch()
    const [isLoading, setIsLoading] = useState(false)
    const [zError, setZError] = useState<ZErrorInterface | null>(null)

    useEffect(() => {
        if (!gExploitations || !gSecteurs) {
            globalFetch(setIsLoading, setZError, dispatch)
        }
    }, [])

    return (
        <ZPage documentTitle="Accueil">
            <ZPageHeader>
                <h1>
                    GREA: <b>G</b>estion <small>des</small> <b>R</b>emplacements{" "}
                    <small>dans les</small> <b>E</b>xploitations <b>A</b>
                    gricoles
                </h1>
            </ZPageHeader>

            <ZPageSectionHelp>
                Cliquez sur le menu <IcoSearch /> pour trouver une exploitation
                et l'ajouter aux favoris ou pour en créer une nouvelle.
            </ZPageSectionHelp>

            <ZPageContent className="row col-12">
                {isLoading && <Loading />}
                {zError && (
                    <ZPageSection>
                        <ZError zError={zError} />
                    </ZPageSection>
                )}
                <ZPageSection>
                    <div className="section-header mb-4">
                        <h2>Mes exploitations favorites</h2>
                    </div>

                    <ExploitationUsersList
                        exploitationUsers={gExploitationUsers?.filter(
                            (eu) => eu.user.id === gConnectedUserId
                        )}
                    />
                </ZPageSection>

                <ZPageSection>
                    <div className="section-header mb-4">
                        <h2>Mon compte</h2>
                    </div>
                    {gConnectedUserId && (
                        <ConnectedUserInfos userId={gConnectedUserId} />
                    )}
                </ZPageSection>

                <Questions />

                {/* 
                <Hcctm />
				*/}
            </ZPageContent>
        </ZPage>
    )
}

// ### STYLES ###################################################################
