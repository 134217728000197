// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import { ZField } from "libs/zForm"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function HoraireFormInner() {
    return (
        <div className="row col-12">
            <ZField
                autoFocus
                name="email"
                label="Email *"
                type="email"
                fieldClassName="col-12"
            />
            <ZField
                name="username"
                label="Username"
                type="text"
                fieldClassName="col-12"
            />
            <div className="row col-12">
                <h6>Rôles:</h6>
                <ZField
                    name="role_user"
                    label="User"
                    type="checkbox"
                    fieldClassName="col-4"
                    fieldStyle="SWITCH"
                />
                <ZField
                    name="role_agent"
                    label="Agent"
                    type="checkbox"
                    fieldClassName="col-4"
                    fieldStyle="SWITCH"
                />
                <ZField
                    name="role_admin"
                    label="Admin"
                    type="checkbox"
                    fieldClassName="col-4"
                    fieldStyle="SWITCH"
                />
            </div>
        </div>
    )
}
