// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React, { useRef } from "react"
import ReactToPrint from "react-to-print"
import { Button } from "react-bootstrap"

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
type Props = {
    children: React.ReactNode
}
export default function ZReactToPrint({ children }: Props) {
    const componentRef = useRef<HTMLHeadingElement>(null)
    return (
        <div>
            <ReactToPrint
                trigger={() => functionTrigger()}
                content={() => componentRef.current || null}
            />
            <div ref={componentRef}>{children}</div>
        </div>
    )
}

const functionTrigger = () => <Button>Print this out!</Button>

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS
