// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import { ZField } from "libs/zForm"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function ExploitationFormInner() {
    return (
        <div className="row col-12">
            <ZField
                autoFocus
                name="nom"
                label="Nom *"
                type="text"
                fieldClassName="col-12"
            />
            <ZField
                name="adresse"
                label="adresse"
                type="text"
                fieldClassName="col-12"
            />
        </div>
    )
}
