/* eslint-disable react/prop-types */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import Button from "react-bootstrap/Button"
import { useAppDispatch } from "store/store"
// import { randomHexColor } from "utils/fonctions"
import { setUserForm } from "store/dataReducer"
import { IcoEdit } from "libs/zIcones"
import { User } from "types"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
UserButtonEdit.defaultProps = {
    className: null,
}

export default function UserButtonEdit({
    user,
    className = "",
}: {
    user: User
    className?: string | null
}) {
    const dispatch = useAppDispatch()

    function handleOnClick() {
        console.log(user)
        dispatch(setUserForm(user))
    }

    return (
        <Button
            className={`btn-warning btn-sm ${className}`}
            onClick={() => handleOnClick()}
            title="Modifier l'utilisateur"
        >
            <IcoEdit /> Modifier
        </Button>
    )
}
