// ### DOCUMENTATION ############################################################

// ### IMPORTS ##################################################################

import React, { useState } from "react"
import { useAppSelector, useAppDispatch } from "store/store"
import {
    exploitationUserCreateSuccess,
    exploitationUserDeleteSuccess,
} from "store/dataReducer"
import { Button } from "react-bootstrap"
import { IcoStar, IcoStarOutline } from "libs/zIcones"

import {
    zApiExploitationAddToMyFavorite,
    zApiExploitationRemoveFromMyFavorite,
} from "utils/api"

// ### TYPES ####################################################################

FavorisButton.defaultProps = {
    className: "",
}

// ### FUNCTIONS ################################################################

// ### COMPONENT ################################################################

export default function FavorisButton({
    exploitationId,
    className,
}: {
    exploitationId: number
    className?: string
}) {
    const dispatch = useAppDispatch()
    const { gExploitationUsers } = useAppSelector((state) => state.data)
    const [isLoading, setIsLoading] = useState(false)

    const explUser = gExploitationUsers?.filter(
        (eu) => eu.exploitation.id === exploitationId
    )?.[0]

    function clickRemoveExploitationUser(euId: number) {
        if (
            !window.confirm(
                "Voulez‑vous retirer cette exploitation de vos favoris ?"
            )
        ) {
            return
        }
        setIsLoading(true)
        zApiExploitationRemoveFromMyFavorite(euId).then((response: any) => {
            if (response.zError) {
                alert("Error")
                // setFetchZError(response.zError)
            } else {
                dispatch(exploitationUserDeleteSuccess(euId))
            }
            setIsLoading(false)
        })
    }

    function clickAddExploitationUser() {
        setIsLoading(true)
        zApiExploitationAddToMyFavorite(exploitationId).then(
            (response: any) => {
                if (response.zError) {
                    alert("Error")
                    // setFetchZError(response.zError)
                } else {
                    dispatch(
                        exploitationUserCreateSuccess(response.exploitationUser)
                    )
                }
                setIsLoading(false)
            }
        )
    }

    /*
    if (!explUser) {
        return null
    }
	*/

    if (isLoading) {
        return (
            <Button className={className + " btn-sm"} variant="warning">
                ...
            </Button>
        )
    }

    if (!explUser) {
        return (
            <Button
                className={className + " btn-sm"}
                variant="secondary"
                onClick={() => clickAddExploitationUser()}
            >
                <IcoStarOutline />
            </Button>
        )
    }

    return (
        <Button
            className={className + " btn-sm"}
            variant="primary"
            onClick={() => clickRemoveExploitationUser(explUser.id)}
        >
            <IcoStar />
        </Button>
    )
}

// ### STYLES ###################################################################
