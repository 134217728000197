// *** slice : “a collection of Redux reducer logic and actions for a single feature in your app.”

// ### DOCUMENTATION ############################################################

// ### IMPORTS ##################################################################

import { createSlice } from "@reduxjs/toolkit"

// ### TYPES ####################################################################

// ### FUNCTIONS ################################################################

// ### COMPONENT ################################################################

// ### STYLES ###################################################################

// Type for our state
interface AuthState {
    authIsConnected: boolean
    // authId: number | null
    authUserToken: string | null
    authUsername: string | null // util ???????????????
    // authEmail: string | null // util ???????????????
    // Roles:
    authIsDev: boolean
    authIsAdmin: boolean
    authIsAgent: boolean
}

// Initial state
const initialState: AuthState = {
    authUsername: localStorage.getItem("username") || null,
    authUserToken: localStorage.getItem("userToken") || null,
    authIsConnected: localStorage.getItem("userToken") !== null,
    authIsDev: false,
    authIsAdmin: false,
    authIsAgent: false,
}

// Actual Slice
export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        authLoginSuccess(state, action) {
            state.authIsConnected = true
            state.authUserToken = action.payload.apiToken
            state.authUsername = action.payload.username
            console.log("action.payload.roles", action.payload.roles)

            state.authIsDev = action.payload.roles?.includes("ROLE_DEV")
            state.authIsAdmin = action.payload.roles?.includes("ROLE_ADMIN")
            state.authIsAgent = action.payload.roles?.includes("ROLE_AGENT")
            localStorage.setItem("userToken", action.payload.apiToken)
            localStorage.setItem("username", action.payload.username)
        },
        authLogoutSuccessX(state) {
            state.authIsConnected = false
            state.authIsDev = false
            state.authIsAdmin = false
            state.authIsAgent = false
            state.authUserToken = null
            state.authUsername = null
            localStorage.removeItem("userToken")
            localStorage.removeItem("username")
        },
    },
})

export const { authLoginSuccess } = authSlice.actions

export default authSlice.reducer

const { authLogoutSuccessX } = authSlice.actions

export const authLogoutSuccess = () => (dispatch: any) => {
    console.log("'aaaaaaaaaaaaaa")
    dispatch(authLogoutSuccessX())
}
