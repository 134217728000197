/* eslint-disable jsx-a11y/no-autofocus */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import Form from "react-bootstrap/Form"
// import FloatingLabel from "react-bootstrap/FloatingLabel"
import styled from "styled-components"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

type Props = {
    name: string
    label: string
    // type: string
    hint?: React.ReactNode | null
    autoFocus?: boolean
    fieldClassName?: string | null
    inputClassName?: string | null
    value: any
    handleInputChange: (_ev: any) => void
    error: React.ReactNode | null
    fieldStyle?: "CB" | "SWITCH"
    title?: string | undefined
    // requiered // todo
}

ZFieldCheckbox.defaultProps = {
    hint: null,
    autoFocus: false,
    fieldClassName: null,
    inputClassName: null,
    // eslint-disable-next-line react/default-props-match-prop-types
    fieldStyle: "CB",
    title: undefined,
}

export default function ZFieldCheckbox({
    name,
    label,
    //  type,// fieldStyle,
    hint,
    autoFocus,
    fieldClassName,
    inputClassName,
    value,
    handleInputChange,
    error,
    fieldStyle,
    title,
}: Props) {
    return (
        <Styledfieldset className={fieldClassName + ""}>
            <Form.Check
                label={label}
                className={inputClassName + " "}
                autoFocus={autoFocus}
                type={fieldStyle === "SWITCH" ? "switch" : "checkbox"}
                name={name}
                id={"form-cb-" + name}
                title={title}
                checked={value}
                onChange={() =>
                    handleInputChange({
                        target: { name, value: !value },
                    })
                }
            />
            {hint && <div className="zFieldHint text-info">{hint}</div>}
            {error && <div className="zFieldError text-danger">{error}</div>}
        </Styledfieldset>
    )
}
// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS

const Styledfieldset = styled.fieldset`
    label,
    input {
        cursor: pointer;
    }
`
