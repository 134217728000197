/* eslint-disable react/destructuring-assignment */
// ### IMPORTS ##################################################################

import React from "react"

import { Secteur, User } from "types"
import ZTable from "libs/zTable/ZTable"
import SecteurNavlinkAndFlag from "features/secteur/SecteurNavlinkAndFlag"
import { IcoFarmer } from "libs/zIcones"

// ### TYPES ####################################################################

// ### FUNCTIONS ################################################################
/*
function showContacts(
    contactSecteur: { id: number; fonction: string; contact: Contact }[] | null
) {
    // console.log("aaa")
    return (
        <ul>
            {contactSecteur?.map((ce) => (
                <li key={"ce" + ce.id}>
                    {ce.contact.nom} {ce.contact.prenom} ({ce.fonction})
                    {/* <ContactItemToggelable contact={ce.contact} /> * /}
                    <ul>
                        {ce.contact.telephones?.map((tel) => (
                            <li key={"tel" + tel.id}>{tel.numero}</li>
                        ))}
                    </ul>
                </li>
            ))}
        </ul>
    )
}
*/
function ShowUsers(users: User[] | null) {
    //  console.log("aaa")
    return (
        <div>
            {users?.map((user) => (
                <div key={"user" + user.id}>
                    • <IcoFarmer />
                    {user.username}
                </div>
            ))}
        </div>
    )
}

function showSecteur(secteur: Secteur | null) {
    return <SecteurNavlinkAndFlag id={secteur?.id} />
}

// ### COMPONENT ################################################################

export default function SecteursTable({ secteurs }: { secteurs: Secteur[] }) {
    //  const filterdSeries = series.filter((ser) => ser.statusCouple === "watch")
    // const filterdSeries = series.filter(() => true)
    // console.log("filterdSeries")
    // console.log(filterdSeries)
    const tableColumns = [
        {
            name: "id",
            text: "id",
        },
        {
            name: "nom",
            text: "Nom",
            cellHtml: (secteur: any) => showSecteur(secteur),
            sortValue: (secteur: any) => secteur.nom,
        },
        {
            name: "agents",
            text: "agents",
            cellHtml: (secteur: any) => ShowUsers(secteur.users),
        },
        {
            name: "contacts",
            text: "contacts",
            cellHtml: (secteur: any) => secteur.contacts?.length,
            sortValue: (secteur: any) => secteur.contacts?.length || 0,
        },
        {
            name: "exploitations",
            text: "Exploitations",
            cellHtml: (secteur: any) => secteur.exploitations?.length,
            sortValue: (secteur: any) => secteur.exploitations?.length || 0,
        },
        {
            name: "description",
            text: "description",
        },
    ]

    return (
        <div className="row col-12 p-1 m-4 zzAutoScrollX">
            <ZTable
                columns={tableColumns}
                data={secteurs}
                className="table table-bordered table-xs table-striped table-hover bg-light"
            />
        </div>
    )
}
