/* eslint-disable no-unused-vars */ // !!!!!!!!!!!!!!!!!!!!

// ### DOCUMENTATION ############################################################

// ### IMPORTS ##################################################################

import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { useAppSelector, useAppDispatch } from "store/store"
import { ZErrorInterface, ZError } from "libs/zError"
import { NavLink } from "react-router-dom"
import Loading from "ui/Loading"
import {
    ZPage,
    ZPageHeader,
    ZPageContent,
    ZPageSection,
    // ZPageSectionHelp,
    ZPageSectionTodo,
} from "libs/zPage"
import { globalFetch } from "utils/api"
import ExploitationsSearch from "features/exploitation/search/ExploitationsSearch"
import ExploitationButtonCreate from "features/exploitation/crud/ExploitationButtonCreate"
import ExploitationForm from "features/exploitation/crud/ExploitationForm"

// ### TYPES ####################################################################

// ### FUNCTIONS ################################################################

// ### COMPONENT ################################################################

export default function PageExploitationsSearch() {
    const dispatch = useAppDispatch()
    const { gExploitations, exploitationForm, gSecteurs } = useAppSelector(
        (state) => state.data
    )
    const [isLoading, setIsLoading] = useState(false)
    const [zError, setZError] = useState<ZErrorInterface | null>(null)

    useEffect(() => {
        if (!gExploitations || !gSecteurs) {
            globalFetch(setIsLoading, setZError, dispatch)
        }
    }, [])

    return (
        <ZPage documentTitle="Exploitations">
            {exploitationForm && <ExploitationForm />}
            <ZPageHeader>
                <h1>
                    Rechercher une Exploitation <del>ou un Contact</del>
                    <ExploitationButtonCreate className="float-end" />
                </h1>
            </ZPageHeader>

            <ZPageContent className="row col-12">
                {isLoading && <Loading />}
                {zError && (
                    <ZPageSection>
                        <ZError zError={zError} />
                    </ZPageSection>
                )}
                <ExploitationsSearch />

                <ZPageSectionTodo className="col-md-6 col-12">
                    <h2>Todo:</h2>
                    <ul>
                        <li>
                            Filtres :
                            <ul>
                                <li>Exploitations || contacts</li>
                                <li>Nom || Nom et Adresse || Adresse</li>
                                <li>
                                    Mes secteurs{" "}
                                    <small>
                                        (y compris les exploitations sans
                                        secteur)
                                    </small>{" "}
                                    (...) || Tous les secteurs
                                </li>
                            </ul>
                        </li>
                    </ul>
                </ZPageSectionTodo>
            </ZPageContent>
        </ZPage>
    )
}
// ### STYLES ###################################################################

const StyledItemExploitation = styled(NavLink)`
    margin: 0.4em;
    margin-bottom: 0.8em;
    padding: 0.4em;
    display: block;
    text-decoration: none;
    &:hover {
        background-color: #e3ffd7;
    }
`
