// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import { useAppSelector, useAppDispatch } from "store/store"
import ZModal from "libs/ZModal"
import { ZForm } from "libs/zForm"
import {
    setExploitationForm,
    // UserCreateSuccess,
    UserUpdateSuccess,
    UserDeleteSuccess,
} from "store/dataReducer"
import { Exploitation } from "types"

import {
    zApiExploitationCreate,
    zApiExploitationUpdate,
    zApiExploitationDelete,
} from "utils/api"

import ExploitationFormInner from "./ExploitationFormInner"

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

// eslint-disable-next-line no-unused-vars
export default function ExploitationForm() {
    const dispatch = useAppDispatch()
    const { exploitationForm } = useAppSelector((state) => state.data)

    const customValidate = (values: Exploitation) => {
        let errors = {}

        if (!values.nom) {
            errors = {
                ...errors,
                nom: "Le nom ne peut être vide.",
            }
        }
        return errors
    }

    if (!exploitationForm) {
        return null
    }

    return (
        <ZModal /* styles={{ maxWidth: "460px" }} */
            isShown
            hide={() => null}
            headerText="aaaaaaaaa"
        >
            <ZForm
                initialData={exploitationForm}
                customValidate={customValidate}
                closeForm={() => dispatch(setExploitationForm(null))}
                // *** Create:
                createTitle="Ajouter une exploitation"
                createFunction={zApiExploitationCreate}
                createSuccessFunction={
                    (_result: any) => window.location.reload()
                    //  dispatch(UserCreateSuccess(result))
                }
                // *** Update:
                updateTitle={`Modifier l'exploitation ${exploitationForm?.nom}`}
                updateFunction={zApiExploitationUpdate}
                updateSuccessFunction={(result: any) =>
                    dispatch(UserUpdateSuccess(result))
                }
                // *** Delete:
                deleteTitle={`Supprimer l'exploitation ${exploitationForm?.nom}`}
                deleteConfirmText=""
                deleteFunction={zApiExploitationDelete}
                deleteSuccessFunction={(_result: any) =>
                    dispatch(UserDeleteSuccess(exploitationForm.id))
                }
            >
                <ExploitationFormInner />
            </ZForm>
        </ZModal>
    )
}
