/* eslint-disable react/prop-types */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
// import { useAppSelector } from "store/store"
import { LinkContainer } from "react-router-bootstrap"
import { NavDropdown } from "react-bootstrap"
import { FaBan } from "react-icons/fa"
import { IcoUsers, IcoContactCard, IcoFarm, IcoMap } from "libs/zIcones"

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function DropdownAdmin() {
    /*
    const { userIsWebmaster } = useAppSelector((state) => state.connectedUser)

    if (!userIsWebmaster) {
        return null
    }
	*/
    return (
        <NavDropdown
            title={<FaBan />}
            id="basic-nav-dropdown"
            className=" d-md-none d-block" // ms-auto
            align="end"
        >
            <NavDropdown.Item href="#" disabled>
                Ce menu ne s'affichera que pour <br />
                les utilisateurs ayant le rôle <b>Admin</b> !
            </NavDropdown.Item>

            <NavDropdown.Divider />

            <LinkContainer to="/admin/users">
                <NavDropdown.Item>
                    <IcoUsers /> Gestion des utilisateurs
                </NavDropdown.Item>
            </LinkContainer>

            <LinkContainer to="/admin/exploitations">
                <NavDropdown.Item>
                    <IcoFarm /> Gestion des exploitations
                </NavDropdown.Item>
            </LinkContainer>

            <LinkContainer to="/admin/secteurs">
                <NavDropdown.Item>
                    <IcoMap /> Gestion des Secteurs
                </NavDropdown.Item>
            </LinkContainer>

            <LinkContainer to="/admin/contacts">
                <NavDropdown.Item>
                    <IcoContactCard /> Gestion des Contacts
                </NavDropdown.Item>
            </LinkContainer>

            {/* 
            <LinkContainer to="/admin">
                <NavDropdown.Item>
                    <FaUserFriends /> Dashboard
                </NavDropdown.Item>
            </LinkContainer>

            <NavDropdown.Item href="/admin/todo">
                <FaQuestion /> Todo
            </NavDropdown.Item>
            <NavDropdown.Item href="/dev/test">
                <FaRoute /> Test
            </NavDropdown.Item>
            <NavDropdown.Item href="#action/3.4" disabled>
                <FaRoute /> disabled
            </NavDropdown.Item>
			
			*/}
        </NavDropdown>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS
