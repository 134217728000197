/* eslint-disable react/prop-types */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import Button from "react-bootstrap/Button"
import { useAppDispatch } from "store/store"
// import { randomHexColor } from "utils/fonctions"
import { setUserForm } from "store/dataReducer"
import { IcoPlus } from "libs/zIcones"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function UserButtonCreate({ className = "" }) {
    const dispatch = useAppDispatch()

    // const { workspace } = useAppSelector((state) => state.hPS)

    function handleOnClick() {
        const uForm = {
            id: null,
            username: "",
            email: "",
            roles: [],
            role_user: true,
            role_agent: false,
            role_admin: false,
            // couleur: randomHexColor(),
        }
        console.log(uForm)
        dispatch(setUserForm(uForm))
    }

    return (
        <Button
            className={`btn-primary btn-sm ${className}`}
            onClick={() => handleOnClick()}
            title="Ajouter un utilisateur"
        >
            <IcoPlus /> Ajouter un utilisateur
        </Button>
    )
}
