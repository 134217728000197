// ### IMPORTS ##################################################################

import React from "react"
// import { NavLink } from "react-router-dom"
import { User } from "types"
import ZTable from "libs/zTable/ZTable"
import UserButtonEdit from "features/users/crud/UserButtonEdit"
// import ContactItemToggelable from "features/contact/ContactItemToggelable"
import TelephonesToggleListe from "features/telephone/TelephonesToggleListe"
import SecteurNavlinkAndFlag from "features/secteur/SecteurNavlinkAndFlag"

// ### TYPES ####################################################################

// ### FUNCTIONS ################################################################

/*
function showRoles(user: User) {
    //  console.log("aaa")
    return (
        <ul>
            {user.roles
                // ?.filter((rr) => rr !== "ROLE_USER")
                ?.map((rr) => (
                    <li key={"user-" + user.id + "-roles-" + rr}>
                        {rr.replace("ROLE_", "")}
                    </li>
                ))}
        </ul>
    )
}
*/

function showTelephones(user: User) {
    return <TelephonesToggleListe telephones={user.telephones} />
}

function showButtonEdit(user: User) {
    //  console.log("aaa")
    return <UserButtonEdit user={user} className="btn-xs" />
}
function showRoles(user: User) {
    return (
        <>
            {user.role_user && <>[User] </>}
            {user.role_dev && <span className="text-warning">[Dev] </span>}
            {user.role_agent && <span className="text-primary">[Agent] </span>}
            {user.role_admin && <span className="text-danger">[Admin] </span>}
        </>
    )
}
function showSecteurs(user: User) {
    return (
        <ul>
            {user.secteurs
                // ?.filter((rr) => rr !== "ROLE_USER")
                ?.map((sect) => (
                    <li key={"user-" + user.id + "-sect-" + sect.id}>
                        <SecteurNavlinkAndFlag id={sect.id} />
                    </li>
                ))}
        </ul>
    )
}

// ### COMPONENT ################################################################

export default function UsersTable({ users }: { users: User[] }) {
    //  const filterdSeries = series.filter((ser) => ser.statusCouple === "watch")
    // const filterdSeries = series.filter(() => true)
    // console.log("filterdSeries")
    // console.log(filterdSeries)
    const tableColumns = [
        {
            name: "edit",
            text: "edit",
            cellHtml: (user: any) => showButtonEdit(user),
        },
        {
            name: "id",
            text: "id",
        },
        {
            name: "username",
            text: "Username",
            //  cellHtml: (user: any) =>NavLinkExpoitationShow(exploitation.id, exploitation.nom),
        },
        {
            name: "email",
            text: "eMail",
            //  cellHtml: (user: any) =>NavLinkExpoitationShow(exploitation.id, exploitation.nom),
        },
        {
            name: "nom",
            text: "Nom",
            //  cellHtml: (user: any) =>NavLinkExpoitationShow(exploitation.id, exploitation.nom),
        },
        {
            name: "prenom",
            text: "Prénom",
            //  cellHtml: (user: any) =>NavLinkExpoitationShow(exploitation.id, exploitation.nom),
        },
        {
            name: "telephones",
            text: "Téléphones",
            cellHtml: (contact: any) => showTelephones(contact),
            sortValue: (contact: any) => contact.telephones.length,
        },
        {
            name: "adresse",
            text: "Adresse",
        },
        /*
        {
            name: "roles",
            text: "Rôles",
            cellHtml: (user: any) => showRoles(user),
        },
		*/
        {
            name: "role_2",
            text: "Rôles 2",
            cellHtml: (user: User) => showRoles(user),
        },
        {
            name: "exploitations",
            text: "Exploitations",
            // cellHtml: (exploitation: any) => showContacts(exploitation.contactExploitations),
        },
        {
            name: "secteurs",
            text: "Secteurs",
            cellHtml: (user: any) => showSecteurs(user),
            sortValue: (exploitation: any) =>
                exploitation.secteur?.nom || "zzz",
        },
    ]

    return (
        <div className="row col-12 p-1 m-4 zzAutoScrollX">
            <ZTable
                columns={tableColumns}
                data={users}
                className="table table-bordered table-sm table-striped table-hover bg-light"
            />
        </div>
    )
}
