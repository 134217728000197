/* eslint-disable react/prop-types */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import { useAppSelector, useAppDispatch } from "store/store"
import { NavDropdown } from "react-bootstrap"
import { authLogoutSuccess } from "features/auth/utils/authSlice"
import { IcoUser } from "libs/zIcones"
import { FaSignOutAlt } from "react-icons/fa"
import { useNavigate } from "react-router-dom"

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function DropdownConnectedUser() {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const { authUsername } = useAppSelector((state) => state.auth)
    if (!authUsername) {
        return null
    }

    return (
        <NavDropdown
            title={
                <>
                    <IcoUser />
                    <span className="d-none d-md-inline"> {authUsername}</span>
                </>
            }
            id="navMenu-dropdown-connectedUser"
            className="ms-auto"
            align="end"
        >
            <NavDropdown.Item onClick={() => btLogoutClick()}>
                <FaSignOutAlt /> Déconnexion
            </NavDropdown.Item>
            <NavDropdown.Item href="#todo" disabled>
                <FaSignOutAlt /> Déconnecter de tous les appareils
            </NavDropdown.Item>
            <NavDropdown.Divider />
            {/* 
            <NavDropdown.Item href={"/user/" + userId}>
                Mon profil
            </NavDropdown.Item>
			*/}
        </NavDropdown>
    )

    function btLogoutClick() {
        dispatch(authLogoutSuccess())
        navigate("/")
    }
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS
