// ### IMPORTS ##################################################################

import React from "react"
import { ZPageSectionHelp } from "libs/zPage"

// ### TYPES ####################################################################

// ### FUNCTIONS ################################################################

// ### COMPONENT ################################################################

export default function UsersQuestions() {
    return (
        <>
            {/* 
            <ZPageSectionHelp className="col-md-6 col-12">
                <h4>
                    <u>Question User-04</u> : xxxxxxxxxxxxxxxxxx
                </h4>
                <p>pppppppppppppppppppp</p>
                <h4>Réponse:</h4>
                <p>rrrrrrrrrrrrrrrrr</p>
            </ZPageSectionHelp>
			*/}
            <ZPageSectionHelp className="col-md-6 col-12">
                <h2>Help: Les comptes utilisateurs</h2>
                <p>Ces comptes servent à se connecter au site.</p>
                <br />
                <p>
                    Il en faudrait un par personne (agents + bureau), mais si
                    vous avez la flemme, vous pouvez aussi créer un compte{" "}
                    <b>unique</b> pour tous les vacataires. (il faut tout de
                    même fournir une adresse email)
                </p>
            </ZPageSectionHelp>

            <ZPageSectionHelp className="col-md-6 col-12">
                <h2>Help: Création d'un nouveau compte</h2>
                <p>
                    Si vous avez le rôle "Admin", vous pouvez créer un nouveau
                    compte (bouton "Ajouter un personnel")
                    <br />
                    <br />
                    Vous devez saisir l'adresse email du nouvel utilisateur.
                    <br />
                    Un mail lui sera envoyé avec un lien pour s'inscrire et se
                    choisir un mot de passe (uniquement avec l’adresse email que
                    vous avez saisie).
                    <br />
                    <br />
                    <i>
                        Ceci évitera que n'importe qui puisse s'inscrire sur le
                        site, avec un autre mail.
                    </i>
                </p>
            </ZPageSectionHelp>

            <ZPageSectionHelp className="col-md-6 col-12">
                <h2>Help: Les rôles (autorisations)</h2>
                <p>
                    Si vous avez le rôle "Admin", vous pouvez éditer les rôles
                    des autres utilisateurs.
                </p>
                <br />

                <div className="row col-12 border-bottom border-primary">
                    <div className="row col-6">&nbsp;</div>
                    <div className="row col-2">User</div>
                    <div className="row col-2">Admin</div>
                    <div className="row col-2">Agent</div>
                </div>
                <div className="row col-12 border-bottom border-primary">
                    <div className="row col-6">Se connecter au site [1]</div>
                    <div className="row col-2">X</div>
                    <div className="row col-2">-</div>
                    <div className="row col-2">-</div>
                </div>
                <div className="row col-12 border-bottom border-primary">
                    <div className="row col-6">
                        Editer les utilisateurs (et leurs rôles)
                    </div>
                    <div className="row col-2">-</div>
                    <div className="row col-2">X</div>
                    <div className="row col-2">-</div>
                </div>
                <div className="row col-12 border-bottom border-primary">
                    <div className="row col-6">
                        Voir les exploitations + notes + contacts
                    </div>
                    <div className="row col-2">X</div>
                    <div className="row col-2">X</div>
                    <div className="row col-2">X</div>
                </div>
                <div className="row col-12 border-bottom border-primary">
                    <div className="row col-6">Editer les exploitations</div>
                    <div className="row col-2">-</div>
                    <div className="row col-2">X</div>
                    <div className="row col-2">?</div>
                </div>
                <div className="row col-12 border-bottom border-primary">
                    <div className="row col-6">
                        Editer les notes + contacts des exploitations
                    </div>
                    <div className="row col-2">-</div>
                    <div className="row col-2">X</div>
                    <div className="row col-2">X</div>
                </div>

                <br />
                <p>
                    [1]{" "}
                    <span className="text-danger">
                        Vous devez attribuer le rôle User à (quasiment ?) tous
                        les utilisateurs pour qu'ils puissent se connecter !!!
                    </span>
                </p>
                <br />
                <p>"Editer" = Ajouter + Modifier + Supprimer</p>
            </ZPageSectionHelp>
        </>
    )
}
