// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

const forceDev = true // ********* A modifier pour forcer le mode dev (en prod, dev=false)
const forceUseLocal = true // **** A modifier pour forcer l'utilisation de la BDD local (en useLocal, dev=false)
const forceDebugTimetable = false
export const modeVideo = false // **** A modifier pour supprimer des trucs

export function getUserToken() {
    return localStorage.getItem("userToken") // ?????????????????????????????????????????
}

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export const debugTimetable =
    process.env.NODE_ENV === "production" ? false : forceDebugTimetable

export const dev = process.env.NODE_ENV === "production" ? false : forceDev

export const useLocal =
    process.env.NODE_ENV === "production" ? false : forceUseLocal

export const API_BASE_URL = useLocal
    ? "http://grea.zedixi.local/apiz"
    : "https://grea.api.zedixi.com/apiz"
