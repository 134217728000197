/* eslint-disable no-unused-vars */ // !!!!!!!!!!!!!!!!!!!!
// ### DOCUMENTATION ############################################################

// ### IMPORTS ##################################################################

import React, { useState } from "react"
import { Button } from "react-bootstrap"
import styled from "styled-components"

import { useAppSelector, useAppDispatch } from "store/store"
import { setUsers } from "store/dataReducer"

// import ExploitationsTable from "features/exploitation/ExploitationsTable"
// import { Exploitation } from "types"

import { NavLink } from "react-router-dom"
import {
    ZPage,
    ZPageHeader,
    ZPageContent,
    ZPageSection,
    ZPageSectionHelp,
    // ZPageSectionTodo,
} from "libs/zPage"
import { apiFetchUsers } from "utils/api"
import { IcoPlus } from "libs/zIcones"
import UsersTable from "features/users/UsersTable"
import UserButtonCreate from "features/users/crud/UserButtonCreate"
import UsersQuestions from "features/users/UsersQuestions"
import UserForm from "features/users/crud/UserForm"

// ### TYPES ####################################################################

// ### FUNCTIONS ################################################################

// ### COMPONENT ################################################################

export default function PageUsers() {
    const dispatch = useAppDispatch()
    const { users, userForm } = useAppSelector((state) => state.data)
    const [showTable, setShowTable] = useState(false)

    return (
        <ZPage
            documentTitle="Personnel"
            fetchFunction={() => apiFetchUsers()}
            fetchSuccessFunction={(result: any) =>
                dispatch(setUsers(result.users))
            }
        >
            {userForm && <UserForm />}

            <ZPageHeader>
                <h1>
                    Comptes Utilisateurs (listes du personnel)
                    <UserButtonCreate className="float-end" />
                </h1>
                <p>
                    Sur cette page, vous pouve éditer les comptes utilisateurs.
                </p>
                <p className="text-danger">
                    Cette page <del>est</del> sera réservée au admin !
                </p>
            </ZPageHeader>
            <ZPageContent className="row col-12">
                <ZPageSection>
                    {users && <UsersTable users={users} />}
                </ZPageSection>

                <UsersQuestions />
            </ZPageContent>
        </ZPage>
    )
}
// ### STYLES ###################################################################

const StyledItemExploitation = styled(NavLink)`
    margin: 0.4em;
    margin-bottom: 0.8em;
    padding: 0.4em;
    display: block;
    text-decoration: none;
    &:hover {
        background-color: #e3ffd7;
    }
`
