// ### IMPORTS ##################################################################

import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"
import styled from "styled-components"
import Loading from "ui/Loading"
import { authLoginSuccess } from "features/auth/utils/authSlice"
import { useAppDispatch } from "store/store"
import { ZErrorInterface } from "libs/zError"
import { apiLogin } from "features/auth/utils/apiAuth"
import { FieldEmail, FieldPassword } from "./common/formFields"
import FormLoginZError from "./FormLoginZError"

// ### COMPONENT ################################################################

export default function FormLogin() {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [zError, setZError] = useState<ZErrorInterface | null>(null)

    function validateForm() {
        return email.length >= 3 && password.length >= 6
    }

    const handleSubmit = (event: React.SyntheticEvent) => {
        event.preventDefault()
        setIsLoading(true)
        setZError(null)
        // * return:    reponse.user | reponse.zError
        apiLogin(email, password).then((reponse) => {
            if (reponse.user) {
                dispatch(authLoginSuccess(reponse.user))
                navigate("/")
            } else if (reponse.zError) {
                setZError(reponse.zError)
            }

            setIsLoading(false)
        })
    }

    return (
        <StyledLoginForm onSubmit={handleSubmit}>
            <FieldEmail email={email} setEmail={setEmail} />

            <FieldPassword password={password} setPassword={setPassword} />

            {zError && <FormLoginZError zError={zError} />}

            {isLoading ? (
                <Loading />
            ) : (
                <Button
                    size="lg"
                    type="submit"
                    disabled={!validateForm()}
                    className="mt-2"
                >
                    Connexion
                </Button>
            )}
        </StyledLoginForm>
    )
}

// ### STYLES ###################################################################

const StyledLoginForm = styled(Form)``
