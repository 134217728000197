// ### DOCUMENTATION ############################################################

// ### IMPORTS ##################################################################
import React, { useState } from "react"
import styled from "styled-components"
import { useAppSelector } from "store/store"
// import ExploitationsTableSearch from "features/exploitation/listes/ExploitationsTableSearch"
import ExploitationItem from "features/exploitation/listes/ExploitationItem"
import { ZPageSection } from "libs/zPage"
// ### TYPES ####################################################################

// ### FUNCTIONS ################################################################

// ### COMPONENT ################################################################

export default function ExploitationsSearch() {
    const { gExploitations, gContacts } = useAppSelector((state) => state.data)
    const [searchInput, setSearchInput] = useState("")
    const [filteredExploitations, setFilteredExploitations] = useState([])

    if (!gExploitations) {
        return null
    }

    // console.log("** exploitations", gExploitations)
    //  console.log("** filteredExploitations", filteredExploitations)

    const handleChange = (e) => {
        e.preventDefault()
        setSearchInput(e.target.value)
        applyFilters(e.target.value)
    }

    function applyFilters(value) {
        if (value.length > 1) {
            setFilteredExploitations(
                gExploitations.filter(
                    (expl) =>
                        strFormat(expl.nom).match(strFormat(value)) ||
                        strFormat(expl.adresse || "azdqfqdsf").match(
                            strFormat(value)
                        )
                )
            )
        } else {
            setFilteredExploitations([])
        }
    }

    function strFormat(str) {
        str = str.toLowerCase()
        return str
    }

    return (
        <>
            <ZPageSectionSearchBar className="row col-12">
                <div className="row col-12">
                    <input
                        type="search"
                        placeholder="Tapez au moins 2 caractères"
                        onChange={handleChange}
                        value={searchInput}
                        className="col-12 col-md-6"
                    />
                    <div className="col-12 col-md-6">
                        Rechercher parmis les {gExploitations?.length || "..."}{" "}
                        exploitations{" "}
                        <del>et {gContacts?.length || "..."} contacts</del>
                    </div>
                </div>
            </ZPageSectionSearchBar>

            <ZPageSection>
                {filteredExploitations?.length} / {gExploitations?.length}
                <div className="row col-12">
                    {searchInput.length < 2 ? (
                        <div className="text-danger">
                            Vous devez saisir au moins 2 lettres
                        </div>
                    ) : (
                        filteredExploitations.map((expl) => (
                            <ExploitationItem
                                exploitation={expl}
                                key={"search-expl-" + expl.id}
                            />
                        ))
                    )}
                </div>
                {/* 
                <ExploitationsTableSearch
                    exploitations={filteredExploitations}
                />
				*/}
            </ZPageSection>
        </>
    )
}

// ### STYLES ###################################################################

const ZPageSectionSearchBar = styled(ZPageSection)`
    position: sticky;
    top: 40px;
`
