/* eslint-disable react/prop-types */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import Button from "react-bootstrap/Button"
import { useAppDispatch } from "store/store"
import { setExploitationForm } from "store/dataReducer"
import { IcoPlus } from "libs/zIcones"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function ExploitationButtonCreate({ className = "" }) {
    const dispatch = useAppDispatch()

    function handleOnClick() {
        const exForm = {
            id: null,
            nom: "",
            adresse: "",
        }
        console.log("exForm", exForm)
        dispatch(setExploitationForm(exForm))
    }

    return (
        <Button
            className={`btn-primary btn-sm ${className}`}
            onClick={() => handleOnClick()}
            title="Ajouter une exploitation"
        >
            <IcoPlus /> Créer une exploitation
        </Button>
    )
}
