/* eslint-disable no-unused-vars */ // !!!!!!!!!!!!!!!!!!!!
// ### DOCUMENTATION ############################################################

// ### IMPORTS ##################################################################

import React, { useState } from "react"
import { Button } from "react-bootstrap"
import styled from "styled-components"

import { useAppSelector, useAppDispatch } from "store/store"
import { setMesExploitations } from "store/dataReducer"

import { ExploitationUser } from "types"

import { NavLink } from "react-router-dom"

import ExploitationItem from "features/exploitation/listes/ExploitationItem"

// ### TYPES ####################################################################

// ### FUNCTIONS ################################################################

// ### COMPONENT ################################################################

export default function ExploitationUsersList({
    exploitationUsers,
}: {
    exploitationUsers: ExploitationUser[] | null | undefined
}) {
    const { gExploitations } = useAppSelector((state) => state.data)
    if (!exploitationUsers) {
        return <>Aucune exploitation !</>
    }

    return (
        <>
            {exploitationUsers.map((expUser) => {
                const exploitation = gExploitations?.find(
                    (exp) => exp.id === expUser.exploitation.id
                )
                if (exploitation) {
                    return (
                        <ExploitationItem
                            exploitation={exploitation}
                            key={"eeeeeeeeee" + expUser.id}
                        />
                    )
                }
                return <>xx</>
            })}
        </>
    )
}
// ### STYLES ###################################################################

const StyledItemExploitation = styled(NavLink)`
    margin: 0.4em;
    margin-bottom: 0.8em;
    padding: 0.4em;
    display: block;
    text-decoration: none;
    &:hover {
        background-color: #e3ffd7;
    }
`
