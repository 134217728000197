// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import styled from "styled-components"
import { theme } from "styles/theme"
import { FormContext } from "./ZForm"
import ZFormButtonDelete from "./ZFormButtonDelete"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function ZFormHeader() {
    return (
        <FormContext.Consumer>
            {({ updateMode, createTitle, updateTitle }) => (
                <StyledHeader className="row col-12 text-primary">
                    <h2>
                        {updateMode ? updateTitle : createTitle}
                        {updateMode && (
                            <div className="text-danger float-end">
                                <ZFormButtonDelete />
                            </div>
                        )}
                    </h2>
                </StyledHeader>
            )}
        </FormContext.Consumer>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS

/**
 *  La page entiere (headerMenu + date/heure + Filters + Edt)
 */
const StyledHeader = styled.div`
    h2 {
        font-size: 1.2em;
    }
    border-bottom: 1px solid ${theme.colors.c1};
    margin-bottom: 20px !important;
`
