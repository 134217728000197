/* eslint-disable jsx-a11y/no-autofocus */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import Form from "react-bootstrap/Form"
import FloatingLabel from "react-bootstrap/FloatingLabel"

// import styled from "styled-components"
/*
 **
 *
 * !!! La seule difference avec ZFieldText, c'est le onChange avec le parse number
 *
 *
 */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

type Props = {
    name: string
    label: string
    hint?: React.ReactNode | null
    autoFocus?: boolean
    fieldClassName?: string | null
    inputClassName?: string | null
    value: any
    handleInputChange: (_ev: any) => void
    error: React.ReactNode | null
    fieldStyle?: "BS_FLOATING_LABEL" | "SIMPLE"
    step?: number | undefined
    // requiered // todo
}

ZFieldNumber.defaultProps = {
    hint: null,
    autoFocus: false,
    fieldClassName: null,
    inputClassName: null,
    fieldStyle: "BS_FLOATING_LABEL",
    step: 1,
}

export default function ZFieldNumber({
    name,
    label,
    hint,
    autoFocus,
    fieldClassName,
    inputClassName,
    value,
    handleInputChange,
    error,
    fieldStyle,
    step,
}: Props) {
    if (fieldStyle === "BS_FLOATING_LABEL") {
        return (
            <FloatingLabel
                controlId={name}
                label={label}
                className={fieldClassName + " mb-3"}
                // style={type === "checkbox" ? { left: "20px" } : {}}
            >
                <Form.Control
                    className={inputClassName + " "}
                    autoFocus={autoFocus}
                    type="number"
                    name={name}
                    value={value}
                    onChange={(ev) =>
                        handleInputChange({
                            target: { name, value: Number(ev.target.value) },
                        })
                    }
                    step={step}
                />
                {hint && <div className="text-info">{hint}</div>}
                {error && <div className="text-danger">{error}</div>}
            </FloatingLabel>
        )
    }
    console.log("fieldClassName", name + " - " + fieldClassName)
    return (
        <fieldset
            // controlId={name}
            // label={label}
            className={fieldClassName + " mb-3   "}
            // style={type === "checkbox" ? { left: "20px" } : {}}
        >
            <label htmlFor={"form-label-" + name}>{label}</label>
            <input
                className={inputClassName + " "}
                autoFocus={autoFocus}
                type="number"
                name={name}
                value={value}
                onChange={(ev) =>
                    handleInputChange({
                        target: { name, value: Number(ev.target.value) },
                    })
                }
            />
            {hint && <div className="text-info">{hint}</div>}
            {error && <div className="text-danger">{error}</div>}
        </fieldset>
    )
}
