/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */ // !!!!!!!!!!!!!!!!!!!
/* eslint-disable jsx-a11y/click-events-have-key-events */ // !!!!!!!!!!!!!!!!!!!
/* eslint-disable jsx-a11y/anchor-is-valid */ // !!!!!!!!!!!!!!!!!!!
// ### DOCUMENTATION ############################################################

// ### IMPORTS ##################################################################

import React, { useState } from "react"

import { Exploitation } from "types"
import { IcoMapAlt, /* IcoBan, */ IcoPhone, IcoQuestion } from "libs/zIcones"

// import { useAppSelector, useAppDispatch } from "store/store"
// import { setExploitation } from "store/dataReducer"

import {
    // ZPage,
    ZPageHeader,
    ZPageContent,
    //  ZPageSection,
    // ZPageSectionHelp,
    ZPageSectionTodo,
} from "libs/zPage"

// import { Exploitation } from "types"
// import { exploitations } from "utils/data-exploitations"
import ExploitationSectionLocalisation from "features/exploitation/show/ExploitationSectionLocalisation"
import ExploitationSectionContacts from "features/exploitation/show/ExploitationSectionContacts"
import ExploitationSectionNotes from "features/exploitation/show/ExploitationSectionNotes"
// import { apiFetchExploitation } from "features/exploitation/utils/apiExploitation"

// ### TYPES ####################################################################

// ### FUNCTIONS ################################################################

// ### COMPONENT ################################################################

export default function ExploitationShow({
    exploitation,
}: {
    exploitation: Exploitation
}) {
    const [selectedTab, setSelectedTab] = useState("ADRESSE")

    return (
        <>
            <ZPageHeader>
                <h1>
                    Exploitation : <i>{exploitation.nom}</i>
                </h1>
                <ul className="nav nav-tabs">
                    {/* 
                    <li className="nav-item">
                        <a
                            className="nav-link"
                            aria-current="page"
                            href="#"
                            onClick={() => setSelectedTab("HOME")}
                        >
                            <IcoMapAlt /> Home
                        </a>
                    </li>
					*/}
                    <li className="nav-item">
                        <a
                            className={
                                "nav-link " +
                                (selectedTab === "ADRESSE" ? "active" : "")
                            }
                            aria-current="page"
                            href="#"
                            onClick={() => setSelectedTab("ADRESSE")}
                        >
                            <IcoMapAlt /> Adresse (
                            {exploitation.notes && exploitation.notes.length})
                        </a>
                    </li>
                    <li className="nav-item">
                        <a
                            className={
                                "nav-link " +
                                (selectedTab === "CONTACTS" ? "active" : "")
                            }
                            href="#"
                            onClick={() => setSelectedTab("CONTACTS")}
                        >
                            <IcoPhone /> Contacts (
                            {exploitation.contactExploitations &&
                                exploitation.contactExploitations.length}
                            )
                        </a>
                    </li>
                    <li className="nav-item">
                        <a
                            className={
                                "nav-link " +
                                (selectedTab === "NOTES" ? "active" : "")
                            }
                            href="#"
                            onClick={() => setSelectedTab("NOTES")}
                        >
                            <IcoQuestion /> Notes (
                            {exploitation.notes && exploitation.notes.length})
                        </a>
                    </li>
                    {/* 
                    <li className="nav-item">
                        <a
                            className="nav-link disabled"
                            href="#"
                            aria-disabled="true"
                            onClick={() => setSelectedTab("ADMIN")}
                        >
                            <IcoBan /> Admin
                        </a>
                    </li>
					*/}
                </ul>
            </ZPageHeader>
            <ZPageContent className="row col-12">
                {/* 
                <ZPageSectionHelp className="col-md-6 col-12">
                    <h2>Help:</h2>
                    <p>
                        Sur cette page vous trouverez toutes les infos sur
                        l'exploitation séléctionnée.
                        <br />
                    </p>
                </ZPageSectionHelp>
				*/}

                {selectedTab === "ADRESSE" && (
                    <ExploitationSectionLocalisation
                        exploitation={exploitation}
                    />
                )}

                {selectedTab === "CONTACTS" && (
                    <ExploitationSectionContacts exploitation={exploitation} />
                )}

                {selectedTab === "NOTES" && (
                    <ExploitationSectionNotes exploitation={exploitation} />
                )}

                <ZPageSectionTodo className="col-md-6 col-12">
                    <h2>Todo:</h2>
                    <ul>
                        <li>Ajouter/Modifier les notes</li>
                        <li>calculer distance - domicile agent - map</li>
                    </ul>
                </ZPageSectionTodo>
            </ZPageContent>
        </>
    )
}

// ### STYLES ###################################################################
