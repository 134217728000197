/* eslint-disable react/no-unstable-nested-components */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import styled from "styled-components"
// import { theme } from "styles/theme"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

type Props = {
    children?: React.ReactNode
    className?: String | null
}

ZPageSectionTodo.defaultProps = {
    children: null,
    className: "",
}

export default function ZPageSectionTodo({ children, className }: Props) {
    return (
        <StyledZPageSectionTodo className={className + " col-12"}>
            <section>{children}</section>
        </StyledZPageSectionTodo>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS

const StyledZPageSectionTodo = styled.div`
    padding: 10px;
    font-size: 0.9em;
    section {
        background-color: rgb(255, 230, 230);
        border: 1px solid red;
        font-size: 0.8em;
        padding: 5px;
        h2 {
            color: red;
            font-size: 1.3em;
            margin: 0px;
            margin-bottom: 0.6em;
        }
        h3 {
            color: red;
            font-size: 1.2em;
            margin: 0px;
            margin-bottom: 0.4em;
        }
        h4 {
            color: red;
            font-size: 1.1em;
            margin: 0px;
            margin-bottom: 0.2em;
        }
        p {
            padding: 0px;
            padding-left: 0.5em;
            margin: 0px;
        }
        ul {
            margin: 0px;
        }
    }
`
