/* eslint-disable no-unused-vars */ // !!!!!!!!!!!!!!!!!!!!

// ### DOCUMENTATION ############################################################

// ### IMPORTS ##################################################################

import React, { useState, useEffect } from "react"
import { Button } from "react-bootstrap"
import styled from "styled-components"
import { ZErrorInterface, ZError } from "libs/zError"

import { useAppSelector, useAppDispatch } from "store/store"
import { setExploitations } from "store/dataReducer"

// import { Exploitation } from "types"

import { NavLink } from "react-router-dom"
import {
    ZPage,
    ZPageHeader,
    ZPageContent,
    ZPageSection,
    // ZPageSectionHelp,
    ZPageSectionTodo,
} from "libs/zPage"
import Loading from "ui/Loading"
import { globalFetch, apiFetchExploitations } from "utils/api"
import ExploitationsTable from "features/exploitation/listes/ExploitationsTable"

import ExploitationsSearch from "features/exploitation/search/ExploitationsSearch"
import ExploitationItem from "features/exploitation/listes/ExploitationItem"

// ### TYPES ####################################################################

// ### FUNCTIONS ################################################################

// ### COMPONENT ################################################################

export default function PageExploitations() {
    const { gExploitations, gExploitationUsers, gSecteurs, gConnectedUserId } =
        useAppSelector((state) => state.data)
    const dispatch = useAppDispatch()
    const [isLoading, setIsLoading] = useState(false)
    const [zError, setZError] = useState<ZErrorInterface | null>(null)
    const [showTable, setShowTable] = useState(true)

    useEffect(() => {
        if (!gExploitations || !gSecteurs) {
            globalFetch(setIsLoading, setZError, dispatch)
        }
    }, [])

    return (
        <ZPage
            documentTitle="Exploitations"
            /*
            fetchFunction={() => apiFetchExploitations()}
            fetchSuccessFunction={(result: any) =>
                dispatch(setExploitations(result.exploitations))
            }
			*/
        >
            <ZPageHeader>
                <h1>Listes des Exploitations</h1>
                <p>
                    Sur cette page, vous <del>pouvez</del> pourrez importer et
                    éditer les exploitation.
                </p>
                <p className="text-danger">
                    Cette page <del>est</del> sera réservée au admin !
                </p>
            </ZPageHeader>
            {isLoading && <Loading />}
            {zError && (
                <ZPageSection>
                    <ZError zError={zError} />
                </ZPageSection>
            )}

            <ExploitationsSearch />

            <ZPageContent className="row col-12">
                {gExploitations && (
                    <ZPageSection>
                        <Button
                            className="btn-sm"
                            onClick={() => setShowTable(!showTable)}
                        >
                            Affichage
                        </Button>
                        {showTable ? (
                            <ExploitationsTable
                                exploitations={gExploitations}
                            />
                        ) : (
                            <>
                                {gExploitations.map((exp) => (
                                    <ExploitationItem
                                        key={"exp-" + exp.id}
                                        exploitation={exp}
                                    />
                                ))}
                            </>
                        )}
                    </ZPageSection>
                )}
                <ZPageSectionTodo className="col-md-6 col-12">
                    <h2>Todo:</h2>
                    <ul>
                        <li>Ajouter / Modifier des Exploitations</li>
                    </ul>
                </ZPageSectionTodo>
            </ZPageContent>
        </ZPage>
    )
}
// ### STYLES ###################################################################

const StyledItemExploitation = styled(NavLink)`
    margin: 0.4em;
    margin-bottom: 0.8em;
    padding: 0.4em;
    display: block;
    text-decoration: none;
    &:hover {
        background-color: #e3ffd7;
    }
`
