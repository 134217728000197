/* eslint-disable jsx-a11y/no-static-element-interactions */ // !!!!!!!!!!!!!!!!!!!
/* eslint-disable jsx-a11y/click-events-have-key-events */ // !!!!!!!!!!!!!!!!!!!
/* eslint-disable react/destructuring-assignment */ // !!!!!!!!!!!!!!!!!!!
// ### DOCUMENTATION ############################################################

// ### IMPORTS ##################################################################

import React, { useState } from "react"
import styled from "styled-components"
import { NavLink } from "react-router-dom"
// import React, { useState } from "react"

// import SeriesTable from "features/SeriesTable"
// import ExploitationsTable from "features/exploitation/ExploitationsTable"
// import { Exploitation } from "types"
// import { exploitation } from "utils/data-exploitations"
// import { apiFetchSeries } from "api/apis"
// import { ZPage, ZPageHeader, ZPageContent } from "libs/zPage"

import { Contact } from "types"

// ### TYPES ####################################################################

// ### FUNCTIONS ################################################################

// ### COMPONENT ################################################################

export type Props = {
    contact: Contact
    open?: boolean
}

ContactItemToggelable.defaultProps = {
    open: false,
}

export default function ContactItemToggelable({
    contact,
    open = false,
}: Props) {
    const [showDetails, setShowDetails] = useState(open)
    return (
        <StyledContact key={"contact" + contact.id}>
            <span
                className="clickText"
                onClick={() => setShowDetails(!showDetails)}
            >
                <b>{contact.nom}</b> {contact.prenom}
            </span>
            <span> ({contact.fonction})</span>
            {showDetails && (
                <ul>
                    {contact.telephones?.map((tel) => (
                        <li key={"tel" + contact.id + "-" + tel.id}>
                            {" "}
                            <div>
                                <b>{tel.numero}</b> ({tel.genre})
                                {tel.preferentiel ? (
                                    <small>
                                        -preferentiel-
                                        <br />
                                    </small>
                                ) : (
                                    ""
                                )}
                                {tel.commentaire ? (
                                    <i>{`"${tel.commentaire}"`}</i>
                                ) : (
                                    ""
                                )}
                            </div>
                        </li>
                    ))}
                    <NavLink to={"/contact/" + contact.id}>
                        Afficher plus
                    </NavLink>
                </ul>
            )}
        </StyledContact>
    )
}

// ### STYLES ###################################################################

const StyledContact = styled.div`
    /*color: $C1d1 !important;*/
    // background-color: rgb(200, 200, 200);
    ul {
        font-size: 0.8em;
        li {
            margin-bottom: 0.5em;
        }
        border-bottom: 1px solid black;
        //margin-bottom: 0.5em;
    }
    margin-bottom: 0.2em; ;
`
