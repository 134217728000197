// ### DOCUMENTATION ############################################################

// ### IMPORTS ##################################################################

import React from "react"
import { ZPageSection } from "libs/zPage"

import { Exploitation } from "types"

// ### TYPES ####################################################################

// ### FUNCTIONS ################################################################

// ### COMPONENT ################################################################

export default function ExploitationSectionLocalisation({
    exploitation,
}: {
    exploitation: Exploitation
}) {
    // todo Filtrer le nom des adresses (espaces...)
    const queryMap = exploitation.adresse || exploitation.nom

    return (
        <ZPageSection className=" col-md-6 col-12">
            <h4>Localisation</h4>
            <section className="mb-2">
                <div className="row col-12">
                    <h5>
                        Adresse <small>(ou coordonnées GPS)</small>:
                    </h5>
                    {exploitation.adresse || "?"}
                </div>
            </section>
            <section className="mb-2">
                <h5>Carte: </h5>
                <iframe
                    width="100%"
                    height="240"
                    title="Google Map"
                    src={
                        "https://maps.google.fr/maps?place&q=" +
                        queryMap +
                        "&output=embed"
                    }
                />
            </section>
            <section>
                <h5>Notes de localisation :</h5>
                <ul>
                    <li>
                        Prendre la route "Machin" et tourner à droite apres le
                        garage "Truc"
                    </li>
                    <li>Suivre la route, l'éntrée est derrière la ferme.</li>
                    <li>Ouvrir le portail avec le locket à gauche</li>
                    <li>Attention aux poules sur le chemin !!!</li>
                </ul>
            </section>
        </ZPageSection>
    )
}

// ### STYLES ###################################################################
