// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import { Button } from "react-bootstrap"
import { IcoDelete } from "libs/zIcones"
import { FormContext } from "./ZForm"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function ZFormButtonDelete() {
    return (
        <FormContext.Consumer>
            {({ setDeleteFormIsOpen }) => (
                <Button
                    className="float-end btn-sm"
                    variant="danger"
                    onClick={() => setDeleteFormIsOpen(true)}
                >
                    <IcoDelete />
                </Button>
            )}
        </FormContext.Consumer>
    )
}
