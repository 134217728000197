/* eslint-disable react/prop-types */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import styled from "styled-components"
import { createPortal } from "react-dom"

import { theme } from "styles/theme"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function ZxModal({ children, styles }) {
    return createPortal(
        <div className="zxModalContainer">
            <StyledOpaqueBackground />
            <StyledModal style={styles}>{children}</StyledModal>
        </div>,
        document.getElementById("root")
    )
}

const StyledOpaqueBackground = styled.div`
    background-color: ${theme.colors.c1};
    opacity: 0.6;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    z-index: 1000;
`

const StyledModal = styled.div`
    position: fixed;
    background-color: white;
    z-index: 2000;
    max-height: 90%;
    max-width: 100%;
    overflow-y: auto;
    padding: 10px;
    border: 2px solid ${theme.colors.c1d3};
    /*
   
    // 
    
    /*top: 0;* /
    /*bottom:0;* /

*/

    // inset: 0; /* inset sets all 4 values (top right bottom left) much like how we set padding, margin etc., */
    margin: auto; /* Will not center vertically and won't work in IE6/7. */
    transition: all 0.3s ease-in-out;
    top: 5%;
    left: 0;
    right: 0;
`
