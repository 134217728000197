// ### IMPORTS ##################################################################

import React from "react"
import { Exploitation } from "types"
import { NavLink } from "react-router-dom"
import styled from "styled-components"
import SecteurNavlinkAndFlag from "features/secteur/SecteurNavlinkAndFlag"
import FavorisButton from "features/exploitation/common/FavorisButton"

// ### TYPES ####################################################################

// ### FUNCTIONS ################################################################

// ### COMPONENT ################################################################

export default function ExploitationItem({
    exploitation,
}: {
    exploitation: Exploitation
}) {
    return (
        <StyledItemExploitation className="col-12">
            <div className="content row col-12  border border-primary">
                <NavLink
                    className="col-10"
                    to={"/exploitation/" + exploitation.id}
                    title={exploitation.nom}
                >
                    <span className="nom">{exploitation.nom}</span>

                    <div className="secteur float-end">
                        <SecteurNavlinkAndFlag
                            id={exploitation.secteur?.id}
                            asNavLink={false}
                        />
                    </div>

                    <div className="adresse">{exploitation.adresse}</div>
                </NavLink>

                <div className="row cell_favoris col-2">
                    <FavorisButton
                        exploitationId={exploitation.id}
                        className="col-12"
                    />
                </div>
            </div>
        </StyledItemExploitation>
    )
}

// ### STYLES ###################################################################

const StyledItemExploitation = styled.div`
    margin: 0%;
    margin-bottom: 0.3em !important;
    padding: 0px;
    // background-color: cyan;

    .content {
        // background-color: yellow;
        padding: 0px;
        a {
            text-decoration: none;
            padding: 5px;
            &:hover {
                background-color: #e3ffd7;
            }
        }

        .nom {
            // color: blue;
            font-weight: bold;
        }
        .adresse {
            color: black;
            font-size: 0.8em;
        }
        .secteur {
            color: black;
            font-size: 0.7em;
        }

        .cell_favoris {
            padding: 5px;
        }
    }
`
