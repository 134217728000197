/* eslint-disable no-unused-vars */ // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// ### IMPORTS ##################################################################

import React from "react"
import { NavLink } from "react-router-dom"
import { Secteur, Contact, Exploitation } from "types"
import ZTable from "libs/zTable/ZTable"
import ContactItemToggelable from "features/contact/ContactItemToggelable"
import SecteurNavlinkAndFlag from "features/secteur/SecteurNavlinkAndFlag"
import FavorisButton from "features/exploitation/common/FavorisButton"

// ### TYPES ####################################################################

// ### FUNCTIONS ################################################################

function NavLinkExpoitationShow(
    exploitationId: number,
    exploitationNom: string
) {
    return (
        <NavLink to={"/exploitation/" + exploitationId}>
            {exploitationNom}
        </NavLink>
    )
}

function showContacts(
    contactExploitations:
        | { id: number; fonction: string; contact: Contact }[]
        | null
) {
    // console.log("aaa")
    return (
        <ul>
            {contactExploitations?.map((ce) => (
                <li key={"ce" + ce.id}>
                    {ce.contact.nom} {ce.contact.prenom} ({ce.fonction})
                    {/* <ContactItemToggelable contact={ce.contact} /> */}
                    <ul>
                        {ce.contact.telephones?.map((tel) => (
                            <li key={"tel" + tel.id}>{tel.numero}</li>
                        ))}
                    </ul>
                </li>
            ))}
        </ul>
    )
}
function showAgents(contacts: Contact[] | null) {
    //  console.log("aaa")
    return (
        <ul>
            {contacts
                ?.filter((cc) => cc.fonction === "Agent")
                .map((contact) => (
                    <li key={"contact" + contact.id}>
                        <ContactItemToggelable contact={contact} />
                    </li>
                ))}
        </ul>
    )
}

function showSecteur(secteur: Secteur | null) {
    return <SecteurNavlinkAndFlag id={secteur?.id} />
}

function showFavoris(exploitation: Exploitation) {
    return <FavorisButton exploitationId={exploitation.id} />
}

// ### COMPONENT ################################################################

export default function ExploitationsTable({
    exploitations,
}: {
    exploitations: Exploitation[]
}) {
    //  const filterdSeries = series.filter((ser) => ser.statusCouple === "watch")
    // const filterdSeries = series.filter(() => true)
    // console.log("filterdSeries")
    // console.log(filterdSeries)
    const tableColumns = [
        {
            name: "id",
            text: "id",
        },
        {
            name: "favoris",
            text: "",
            cellHtml: (exploitation: any) => showFavoris(exploitation),
        },
        {
            name: "nom",
            text: "nom",
            cellHtml: (exploitation: any) =>
                NavLinkExpoitationShow(exploitation.id, exploitation.nom),
        },
        {
            name: "adresse",
            text: "adresse",
        },
        /*
        {
            name: "agents",
            text: "agents",
            cellHtml: (exploitation: any) => showAgents(exploitation.contacts),
        },
        {
            name: "contacts",
            text: "contacts",
            cellHtml: (exploitation: any) =>
                showContacts(exploitation.contactExploitations),
        },
		*/
        {
            name: "secteur",
            text: "Secteur",
            cellHtml: (exploitation: any) => showSecteur(exploitation.secteur),
            sortValue: (exploitation: any) =>
                exploitation.secteur?.nom || "zzz",
        },
    ]

    return (
        <div className="row col-12 p-1 m-4 zzAutoScrollX">
            <ZTable
                columns={tableColumns}
                data={exploitations}
                className="table table-bordered table-sm table-striped table-hover bg-light"
            />
        </div>
    )
}
