// ### DOCUMENTATION ############################################################

// ### IMPORTS ##################################################################

import React from "react"

import { useAppSelector, useAppDispatch } from "store/store"
import { setContacts } from "store/dataReducer"

import ContactsTable from "features/contact/ContactsTable"
import ContactsQuestions from "features/contact/ContactsQuestions"
// import { contacts } from "utils/data-exploitations"
import { ZPage, ZPageHeader, ZPageContent, ZPageSection } from "libs/zPage"
import { apiFetchContacts } from "utils/api"

// ### TYPES ####################################################################

// ### FUNCTIONS ################################################################

// ### COMPONENT ################################################################

export default function PageContats() {
    const dispatch = useAppDispatch()
    const { contacts } = useAppSelector((state) => state.data)
    return (
        <ZPage
            documentTitle="Contats"
            fetchFunction={() => apiFetchContacts()}
            fetchSuccessFunction={(result: any) =>
                dispatch(setContacts(result.contacts))
            }
        >
            <ZPageHeader>
                <h1>Contats</h1>
            </ZPageHeader>
            <ZPageContent className="row col-12">
                <ContactsQuestions />

                <ZPageSection>
                    <h4>Liste des contacts</h4>

                    {contacts ? (
                        <ContactsTable contacts={contacts} />
                    ) : (
                        <p>Problème de chargement de la liste des contacts</p>
                    )}
                </ZPageSection>
            </ZPageContent>
        </ZPage>
    )
}

// ### STYLES ###################################################################
