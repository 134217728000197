/* eslint-disable no-unsafe-finally */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import { createSlice } from "@reduxjs/toolkit"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

interface ConnectedUserState {
    userToken: string | null
    userId: string | null
    username: string | null
    userStatus: string
    loginError: string | null
    userIsWebmaster: boolean
}

const initialState: ConnectedUserState = {
    userToken: localStorage.getItem("userToken"),
    userId: localStorage.getItem("userId"),
    username: localStorage.getItem("username"),
    userStatus: "ANONYMOUS", // 	"ANONYMOUS", "LOGGING", "LOGGED"
    loginError: null,
    userIsWebmaster: localStorage.getItem("userIsWebmaster") === "1",
}

// ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■

// Slice

const slice = createSlice({
    name: "connectedUser",
    initialState,
    reducers: {
        loginStart: (state) => {
            state.userToken = null
            state.userId = null
            state.username = null
            state.userIsWebmaster = false

            state.userStatus = "LOGGING"
            state.loginError = null
        },
        loginSuccess: (state, action) => {
            const tempUserIsWebmaster =
                action.payload.id === "1" || action.payload.id === 1

            localStorage.setItem("userToken", action.payload.tokenEdt)
            localStorage.setItem("userId", action.payload.id)
            localStorage.setItem("username", action.payload.username)
            localStorage.setItem(
                "userIsWebmaster",
                tempUserIsWebmaster ? "1" : "0"
            )

            state.userToken = action.payload.tokenEdt
            state.userId = action.payload.id
            state.username = action.payload.username
            state.userIsWebmaster = tempUserIsWebmaster

            state.userStatus = "LOGGED"
            state.loginError = null
        },
        loginError: (state, action) => {
            localStorage.removeItem("userToken")
            localStorage.removeItem("userId")
            localStorage.removeItem("username")
            localStorage.removeItem("userIsWebmaster")
            state.userToken = null
            state.userId = null
            state.username = null
            state.userIsWebmaster = false

            state.userStatus = "ANONYMOUS"
            state.loginError = action.payload
        },
        logout: (state) => {
            // !!! AJOUTER     navigate("/")   apres le     dispatch(logout())
            localStorage.removeItem("userToken")
            localStorage.removeItem("userId")
            localStorage.removeItem("username")
            localStorage.removeItem("userIsWebmaster")
            state.userToken = null
            state.userId = null
            state.username = null
            state.userIsWebmaster = false

            state.userStatus = "ANONYMOUS"
            state.loginError = null
        },
    },
})
export default slice.reducer

// ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■

export const { loginStart, loginSuccess, loginError, logout } = slice.actions
