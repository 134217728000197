/* eslint-disable no-unsafe-finally */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import { createSlice } from "@reduxjs/toolkit"
import { Exploitation, ExploitationUser, User, Contact, Secteur } from "types"
// import { exploitations } from "utils/data-exploitations"
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

interface DataState {
    exploitations: Exploitation[] | null
    exploitation: Exploitation | null
    exploitationForm: Exploitation | null
    secteurs: Secteur[] | null
    contacts: Contact[] | null
    users: User[] | null
    userForm: User | null
    mesExploitationUsers: ExploitationUser[] | null

    gExploitations: Exploitation[] | null
    gSecteurs: Secteur[] | null
    gContacts: Contact[] | null
    gUsers: User[] | null
    gExploitationUsers: ExploitationUser[] | null
    gConnectedUserId: number | null
}

const initialState: DataState = {
    exploitations: null,
    exploitation: null,
    exploitationForm: null,
    secteurs: null,
    contacts: null,
    users: null,
    userForm: null,
    mesExploitationUsers: null,

    gExploitations: null,
    gSecteurs: null,
    gContacts: null,
    gUsers: null,
    gExploitationUsers: null,
    gConnectedUserId: null,
}

// ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■

// Slice

const slice = createSlice({
    name: "exploitations",
    initialState,
    reducers: {
        setGlobal: (state, actions) => {
            // gExploitations:
            console.log(
                "actions.payload.exploitations",
                actions.payload.exploitations
            )
            state.gExploitations = actions.payload.exploitations

            // gSecteurs:
            console.log("actions.payload.secteurs", actions.payload.secteurs)
            state.gSecteurs = actions.payload.secteurs

            // gContacts:
            console.log("actions.payload.contacts", actions.payload.contacts)
            state.gContacts = actions.payload.contacts

            console.log("------------------")
            // gUsers:
            console.log("actions.payload.users", actions.payload.users)
            state.gUsers = actions.payload.users
            console.log("------------------")

            // gExploitationUsers:
            console.log(
                "actions.payload.exploitationUsers",
                actions.payload.exploitationUsers
            )
            state.gExploitationUsers = actions.payload.exploitationUsers

            // gConnectedUserId:
            console.log(
                "actions.payload.connectedUserId",
                actions.payload.connectedUserId
            )
            state.gConnectedUserId = actions.payload.connectedUserId
        },

        // ■■■■■■■■■■■■■■■■■■■■■■■■■■■ xxxxxxxxxxx

        exploitationUserCreateSuccess: (state, actions) => {
            // console.log("actions.payload", actions.payload)
            state.mesExploitationUsers = actions.payload
            if (state.gExploitationUsers) {
                state.gExploitationUsers = [
                    ...state.gExploitationUsers,
                    actions.payload,
                ]
            }
        },
        exploitationUserDeleteSuccess: (state, actions) => {
            // console.log("actions.payload", actions.payload)
            state.mesExploitationUsers = actions.payload
            if (state.gExploitationUsers) {
                state.gExploitationUsers = state.gExploitationUsers.filter(
                    (eu) => eu.id !== actions.payload
                )
            }
        },
        setMesExploitations: (state, actions) => {
            // console.log("actions.payload", actions.payload)
            state.mesExploitationUsers = actions.payload
        },
        // ■■■■■■■■■■■■■■■■■■■■■■■■■■■ EXPLOITATIONS
        setExploitations: (state, actions) => {
            // console.log("actions.payload", actions.payload)
            state.exploitations = actions.payload
        },
        setExploitation: (state, actions) => {
            // console.log("actions.payload", actions.payload)
            state.exploitation = actions.payload
        },
        setExploitationForm: (state, actions) => {
            // console.log("actions.payload", actions.payload)
            state.exploitationForm = actions.payload
        },
        // ■■■■■■■■■■■■■■■■■■■■■■■■■■■ CONTACTS
        setContacts: (state, actions) => {
            // console.log("actions.payload", actions.payload)
            state.contacts = actions.payload
        },
        // ■■■■■■■■■■■■■■■■■■■■■■■■■■■ SECTEURS
        setSecteurs: (state, actions) => {
            // console.log("actions.payload", actions.payload)
            state.secteurs = actions.payload
        },

        // ■■■■■■■■■■■■■■■■■■■■■■■■■■■ 	USERS
        setUsers: (state, actions) => {
            actions.payload.forEach((user: any) => {
                if (user.roles.includes("ROLE_DEV")) user.role_dev = true
                if (user.roles.includes("ROLE_USER")) user.role_user = true
                if (user.roles.includes("ROLE_AGENT")) user.role_agent = true
                if (user.roles.includes("ROLE_ADMIN")) user.role_admin = true
                console.log("+")
            })
            console.log(actions.payload)
            state.users = actions.payload
        },
        setUserForm: (state, actions) => {
            state.userForm = actions.payload
        },
        UserCreateSuccess: (state, actions) => {
            const { user } = actions.payload.user
            if (user.roles.includes("ROLE_DEV")) user.role_dev = true
            if (user.roles.includes("ROLE_USER")) user.role_user = true
            if (user.roles.includes("ROLE_AGENT")) user.role_agent = true
            if (user.roles.includes("ROLE_ADMIN")) user.role_admin = true
            if (state.users) {
                state.users = [...state.users, actions.payload.user]
            }
        },
        UserUpdateSuccess: (state, actions) => {
            const { user } = actions.payload
            if (user.roles.includes("ROLE_DEV")) user.role_dev = true
            if (user.roles.includes("ROLE_USER")) user.role_user = true
            if (user.roles.includes("ROLE_AGENT")) user.role_agent = true
            if (user.roles.includes("ROLE_ADMIN")) user.role_admin = true

            if (state.users) {
                state.users = state.users.map((us) =>
                    us.id === user.id ? user : us
                )
            }
        },
        UserDeleteSuccess: (state, actions) => {
            // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! TODO
            console.log("UsereDeleteSuccess")
            console.log("actions.payload", actions.payload)
            if (state.users) {
                state.users = state.users.filter(
                    (us) => us.id !== actions.payload
                )
            }
        },

        // deprecated :
        setData: (state, actions) => {
            console.log(actions)
            state.exploitations = actions.payload
        },
    },
})
export default slice.reducer

// ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■

export const {
    setGlobal,
    setData,
    setExploitations,
    setExploitation,
    setSecteurs,
    setUsers,
    setContacts,
    setUserForm,
    UserCreateSuccess,
    UserUpdateSuccess,
    UserDeleteSuccess,
    setMesExploitations,
    setExploitationForm,
    exploitationUserCreateSuccess,
    exploitationUserDeleteSuccess,
} = slice.actions
