// ### DOCUMENTATION ############################################################

// ### IMPORTS ##################################################################

import React from "react"
import { ZPageSection } from "libs/zPage"

import { Exploitation } from "types"
import ContactItem from "features/contact/ContactItem"

// ### TYPES ####################################################################

// ### FUNCTIONS ################################################################

// ### COMPONENT ################################################################

export default function ExploitationSectionContacts({
    exploitation,
}: {
    exploitation: Exploitation
}) {
    return (
        <div className="col-md-6 col-12 p-0">
            <ZPageSection className="col-12">
                <h4>Contacts</h4>
                <ul className="pt-4">
                    {exploitation.contactExploitations
                        //  ?.filter((ce) => ce.fonction !== "Agent")
                        .map((ce) => (
                            <li key={"contact" + ce.id}>
                                <ContactItem
                                    contact={ce.contact}
                                    fonction={ce.fonction}
                                />
                            </li>
                        ))}
                </ul>
            </ZPageSection>
            <ZPageSection className="col-12">
                <h4>Agents</h4>
                <div>
                    Agents de remplacements en mission dans cette exploitation.
                </div>
                <ul className="pt-4">
                    {/*
                    {exploitation.contacts
                        ?.filter((cc) => cc.fonction === "Agent")
                        .map((cc) => (
                            <li key={"contact" + cc.id}>
                                <ContactItem contact={cc} />
                            </li>
                        ))}
						*/}
                </ul>
            </ZPageSection>
        </div>
    )
}

// ### STYLES ###################################################################
