// ### IMPORTS ##################################################################

import React from "react"
import { ZPageSectionHelp } from "libs/zPage"

// ### TYPES ####################################################################

// ### FUNCTIONS ################################################################

// ### COMPONENT ################################################################

export default function Questions() {
    return (
        <>
            {/* 
            <ZPageSectionHelp className="col-md-6 col-12">
                <h4>
                    <u>Question Secteur-03</u> : xxxxxxxxxxxxxxxxxx
                </h4>
                <p>pppppppppppppppppppp</p>
                <h4>Réponse:</h4>
                <p>rrrrrrrrrrrrrrrrr</p>
            </ZPageSectionHelp>
            <ZPageSectionHelp className="col-md-6 col-12">
                <h4>
                    <u>Question Secteur-04</u> : xxxxxxxxxxxxxxxxxx
                </h4>
                <p>pppppppppppppppppppp</p>
                <h4>Réponse:</h4>
                <p>rrrrrrrrrrrrrrrrr</p>
            </ZPageSectionHelp>
			*/}
            <ZPageSectionHelp className="col-md-6 col-12">
                <h4>
                    <u>Question Contact-01</u> :
                </h4>
                <p>
                    Jerem: Il peut y avoir plusieurs nom d'exploitant agricole
                    pour une seule exploitation
                </p>
                <h4>Réponse :</h4>
                <p>
                    Tu veux dire dans les "contacts" d'une exploitation, il peut
                    y avoir 2 exploitants responsables ??
                </p>
            </ZPageSectionHelp>
            <ZPageSectionHelp className="col-md-6 col-12">
                <h4>
                    <u>Question User-01</u> :
                </h4>
                <p>
                    Jerem: Du coup <del>l'onglet</del> la page "personnel" n'est
                    pas utile
                </p>
                <h4>Réponse:</h4>
                <p>
                    Si, c'est là qu'on gère les comptes utilisateurs (j'ai
                    renommé la page en "Utilisateurs" ).
                    <br />
                    Création des comptes, attribuer rôles et secteur aux
                    agents...
                </p>
            </ZPageSectionHelp>
            <ZPageSectionHelp className="col-md-6 col-12">
                <h4>
                    <u>Question User-02</u> :
                </h4>
                <p>
                    Jerem: Dans <del>l'onglet</del> la page Contact pour les
                    agents a la place d'exploitation on mettra secteur
                    d'activité
                </p>
                <h4>Réponse:</h4>
                <p>Tu parles bien du tableau de la page "contacts" ?</p>
            </ZPageSectionHelp>
            <ZPageSectionHelp className="col-md-6 col-12">
                <h4>
                    <u>Question User-03</u> : Agents - Secteurs - Exploitations
                </h4>
                <p>
                    A - Un agent peut-il être affecté à plusieurs secteurs ?
                    <br />
                    B - Un agent travail sur combien d'exploitations differentes
                    ? (3, 10 ou 100 ?)
                    <br />
                </p>
            </ZPageSectionHelp>{" "}
            <ZPageSectionHelp className="col-md-6 col-12">
                <h4>
                    <u>Question Exploitation-01</u> : Est ce que tous les agents
                    ont accès aux infos de toutes les exploitations ?
                </h4>
                <p>
                    <b>Si OUI :</b>
                    <br /> Ils peuvent recherchent une exploitation et l'ajouter
                    en favoris.
                </p>
                <p>
                    <b>Si NON :</b>
                    <br /> Un admin devra autoriser chaque agent à consulter les
                    infos des exploitations dans lesquels ils travaillent...
                    <br />
                    C'est mieux pour la confidentialité (les agents n'ont pas
                    accès à tous les numéros de tous les contacts), mais c'est
                    du boulot pour l'admin et s'il oublie de le faire, l'agent
                    ne peut pas voir les infos.
                </p>
                <br />
                <h4>Réponse (si ça vous va):</h4>
                <p>
                    - Les agents verront (par defaut) les exploitations de
                    leur(s) secteur(s)
                    <br />
                    - Ils pourront en ajouter a leurs favoris
                    <br />- Ils pourront tout de même rechercher et ajouter aux
                    favoris des exploitations qui ne sont pas de leur secteur.
                </p>
            </ZPageSectionHelp>
            <ZPageSectionHelp className="col-md-6 col-12">
                <h4>
                    <u>Question Exploitation-02</u> : Import des exploitations
                </h4>
                <p>
                    Les agents pourront créer une éxploitation si elle n'existe
                    pas déjà, mais c'est mieux si on a déjà une base avec toutes
                    les exploitations (ou presques)
                </p>
                <br />
                <p>
                    Les administrateurs devront saisir ou importer les infos des
                    (1500 ?) exploitations
                </p>
                <br />
                <p>
                    Pouvez vous exporter et m'envoyer une liste des
                    exploitations avec toutes les infos (nom, adresse, secteurs,
                    contacts,...) ? (Fichier excel, csv, xml, json...)
                </p>
                <br />
                <h4>Réponse:</h4>
                <p>
                    Jerem: j’aii eu ma RH elle voit avec le président du SR pour
                    l'exportation des contacts des adhérents.
                </p>
            </ZPageSectionHelp>
            <ZPageSectionHelp className="col-md-6 col-12">
                <h4>
                    <u>Question Secteur-01</u> : Secteur / Exploitation
                </h4>
                <p>Une exploitation peut-elle être sur 2 secteurs ?</p>
            </ZPageSectionHelp>
        </>
    )
}
