/* eslint-disable no-unused-vars */ // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */ // !!!!!!!!!!!!!!!!!!!
/* eslint-disable jsx-a11y/click-events-have-key-events */ // !!!!!!!!!!!!!!!!!!!
/* eslint-disable jsx-a11y/anchor-is-valid */ // !!!!!!!!!!!!!!!!!!!
// ### DOCUMENTATION ############################################################

// ### IMPORTS ##################################################################

import React, { useState } from "react"
import { useParams } from "react-router-dom"

// import { Exploitation } from "types"
import { IcoMapAlt, /* IcoBan, */ IcoPhone, IcoQuestion } from "libs/zIcones"

import { useAppSelector, useAppDispatch } from "store/store"
import { setExploitation } from "store/dataReducer"

import {
    ZPage,
    ZPageHeader,
    ZPageContent,
    //  ZPageSection,
    // ZPageSectionHelp,
    ZPageSectionTodo,
} from "libs/zPage"

// import { Exploitation } from "types"
// import { exploitations } from "utils/data-exploitations"
import ExploitationSectionLocalisation from "features/exploitation/show/ExploitationSectionLocalisation"
import ExploitationSectionContacts from "features/exploitation/show/ExploitationSectionContacts"
import ExploitationSectionNotes from "features/exploitation/show/ExploitationSectionNotes"
import { apiFetchExploitation } from "utils/api"
import ExploitationShow from "features/exploitation/show/ExploitationShow"

// ### TYPES ####################################################################

// ### FUNCTIONS ################################################################

// ### COMPONENT ################################################################

export default function PageExploitationShow() {
    const exploitationId = Number(useParams().id) || 0
    if (exploitationId <= 0) {
        return <h1 className="text-danger">Erreur url</h1>
    }

    const dispatch = useAppDispatch()
    const { exploitation } = useAppSelector((state) => state.data)

    /*
    const exploitation: Exploitation | undefined = exploitations.find(
        (ex) => ex.id === exploitationId
    )
	*/

    return (
        <ZPage
            documentTitle={exploitation?.nom || "Exploitation..."}
            fetchFunction={() => apiFetchExploitation(exploitationId)}
            fetchSuccessFunction={(result: any) =>
                dispatch(setExploitation(result.exploitation))
            }
        >
            {exploitation && <ExploitationShow exploitation={exploitation} />}
        </ZPage>
    )
}

// ### STYLES ###################################################################
