/* eslint-disable jsx-a11y/no-static-element-interactions */ // !!!!!!!!!!!!!!!!!!!
/* eslint-disable jsx-a11y/click-events-have-key-events */ // !!!!!!!!!!!!!!!!!!!
/* eslint-disable react/destructuring-assignment */ // !!!!!!!!!!!!!!!!!!!
// ### DOCUMENTATION ############################################################

// ### IMPORTS ##################################################################

import React from "react"
import styled from "styled-components"
import { NavLink } from "react-router-dom"
// import React, { useState } from "react"

// import SeriesTable from "features/SeriesTable"
// import ExploitationsTable from "features/exploitation/ExploitationsTable"
// import { Exploitation } from "types"
// import { exploitation } from "utils/data-exploitations"
// import { apiFetchSeries } from "api/apis"
// import { ZPage, ZPageHeader, ZPageContent } from "libs/zPage"

import { Contact } from "types"
import TelephonesToggleListe from "features/telephone/TelephonesToggleListe"

// ### TYPES ####################################################################

// ### FUNCTIONS ################################################################

// ### COMPONENT ################################################################

export type Props = {
    contact: Contact
    fonction: string
}

export default function ContactItem({ contact, fonction }: Props) {
    return (
        <StyledContact key={"contact" + contact.id}>
            <NavLink to={"/contact/" + contact.id}>
                <b>{contact.nom}</b> {contact.prenom}
            </NavLink>
            <span> ({fonction})</span>

            <TelephonesToggleListe
                telephones={contact.telephones}
                toggleable={false}
            />
        </StyledContact>
    )
}

// ### STYLES ###################################################################

const StyledContact = styled.div`
    /*color: $C1d1 !important;*/
    // background-color: rgb(200, 200, 200);
    ul {
        font-size: 0.8em;
        li {
            margin-bottom: 0.5em;
        }
        // border-bottom: 1px solid black;
        // margin-bottom: 0.5em;
    }
    margin-bottom: 1.5em; ;
`
