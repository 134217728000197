// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
// import ReactDOM from "react-dom"
import ReactDOM from "react-dom/client"

// import ReactGA from "react-ga4"
import { Provider } from "react-redux"
import { dev } from "utils/constants"
// import ErrorBoundary from "utils/ErrorBoundary"
import store from "store/store"
import App from "./App"
import * as serviceWorkerRegistration from "./serviceWorkerRegistration"

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

// * replace console.* for disable log on production
if (process.env.NODE_ENV === "production" || !dev) {
    console.log = () => {}
    console.error = () => {}
    console.debug = () => {}
    console.group = () => {}
    console.groupCollapsed = () => {}
    console.groupEnd = () => {}
}

// ReactGA.initialize("G-QFWWP7567J") // GA4
// ReactGA.send("pageview")
/*
// Check for browser support of service worker
if ("serviceWorker" in navigator) {
    navigator.serviceWorker
        .register("./service-worker.js")
        .then((registration) => {
            console.log(
                "serviceWorker: Registration successful, scope is:",
                registration.scope
            )
        })
        .catch((err) => {
            console.log(
                "Whoops. Service worker registration failed, error:",
                err
            )
        })
}
*/
const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
    <Provider store={store}>
        {/*  <React.StrictMode> */}
        <div id="container-md">
            <App />
        </div>
        {/*    </React.StrictMode> */}
    </Provider>
)
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register()

/*

ReactDOM.render(
    <Provider store={store}>
        <ErrorBoundary>
            <App />
        </ErrorBoundary>
    </Provider>,
    document.getElementById("root")
)
*/
