/* eslint-disable react/prop-types */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import { Navbar, Nav } from "react-bootstrap"
import { LinkContainer } from "react-router-bootstrap"

// import { useAppSelector } from "utils/store"
import styled from "styled-components"
import { useLocal /*  modeVideo,dev,   */ } from "utils/constants"
import { theme } from "styles/theme"
import {
    IcoSearch,
    IcoHome,
    IcoUsers,
    IcoContactCard,
    IcoFarm,
    IcoMap,
} from "libs/zIcones"
// import { IcoSearch } from "libs/zIcones"
// import { Link } from "./Links"
import DropdownConnectedUser from "./DropdownConnectedUser"
import DropdownAdmin from "./DropdownAdmin"
// import DropdownAbout from "./DropdownAbout"

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
NavMenuLink.defaultProps = {
    className: "",
    title: "",
}

function NavMenuLink({
    to,
    children,
    className,
    title,
}: {
    to: string
    children: React.ReactNode
    className?: string
    title?: string
}) {
    return (
        <LinkContainer to={to} className={className} title={title}>
            <Nav.Link>{children}</Nav.Link>
        </LinkContainer>
    )
}
export default function HeaderMenu() {
    const isLocalServer =
        window.location.hostname === "localhost" ||
        window.location.hostname === "127.0.0.1" ||
        window.location.hostname === ""

    return (
        <StyledNavbar
            className="bg-primary bg-gradient fixed-top"
            // user_is_admin={userIsWebmaster ? 1 : 0}
            // is_local_server={isLocalServer ? 1 : 0}
        >
            <Nav className="col-12 me-auto">
                <NavMenuLink to="/" title="GREA" className="text-center">
                    <>
                        <img
                            src="/img/app-logo/app-logo-32.png"
                            alt="wl"
                            className="wl-logo"
                        />
                        {/* 
                        <div className="app-title me-2 text-light">GREA</div>
						*/}
                    </>
                </NavMenuLink>
                <div id="NavMenuLinks">
                    <NavMenuLink to="/">
                        <IcoHome />
                    </NavMenuLink>
                    <NavMenuLink to="/exploitations/search">
                        <IcoSearch />
                    </NavMenuLink>

                    <NavMenuLink
                        to="/admin/users"
                        className="d-none d-md-inline"
                    >
                        <IcoUsers /> Utilisateurs
                    </NavMenuLink>

                    <NavMenuLink
                        to="/admin/exploitations"
                        className="d-none d-md-inline"
                    >
                        <IcoFarm /> Exploitations
                    </NavMenuLink>

                    <NavMenuLink
                        to="/admin/secteurs"
                        className="d-none d-md-inline"
                    >
                        <IcoMap /> Secteurs
                    </NavMenuLink>
                    <NavMenuLink
                        to="/admin/contacts"
                        className="d-none d-md-inline"
                    >
                        <IcoContactCard /> Contacts
                    </NavMenuLink>
                </div>
                <DropdownAdmin />

                {useLocal && (
                    <div className="badge bg-info">
                        LOCAL
                        <br />
                        BDD
                    </div>
                )}
                {isLocalServer && (
                    <div className="badge bg-success ">
                        LOCAL
                        <br />
                        HOST
                    </div>
                )}
                <DropdownConnectedUser />
            </Nav>
        </StyledNavbar>
    )
}
// ### STYLES ###################################################################

const StyledNavbar = styled(Navbar)`
    // background-color: ${theme.colors.c1};

    height: 50px;
    width: 100% !important;
    display: flex;
    align-items: center;
    z-index: 400px;
    .badge {
        background-color: orange;
        align-self: center;
        font-size: 0.5em;
    }

    .app-title {
        font-size: 0.8em;
    }

    #NavMenuLinks {
        //  background-color: orange;
        padding: 5px;
        display: flex;
        flex-grow: 1;
        justify-content: space-around;
        align-items: center;
        // font-size: 1em;

        a {
            color: ${theme.colors.c1l4};

            &.active {
                color: white !important;
            }
        }
    }

    .nav-link {
        //  background-color: purple;
        padding: 0;
    }

    .dropdown {
        //   background-color: yellow;
        padding: 5px !important; // !!!!!!!!!!!!!

        #navMenu-dropdown-connectedUser {
            color: ${theme.colors.c1l4};
        }
    }

    /*

    .container-fluid {
        width: 100% !important;
        margin-left: 0px;
        padding: 0px;
    }
    .container {
        width: 100% !important;
        padding: 0px;
        min-width: 100% !important;
        margin-left: 0px;
    }

    .nav-link {
        font-size: 0.9em !important;
    }

    #user-basic-nav-dropdown {
        margin-left: auto !important;
    }
*/
`
