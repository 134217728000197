/* eslint-disable no-unused-vars */ // !!!!!!!!!!!!!!!!!!!
// ### IMPORTS ##################################################################

import React, { useState } from "react"
import { Button } from "react-bootstrap"
import { NavLink } from "react-router-dom"
import { Telephone, Contact, Exploitation } from "types"
import ZTable from "libs/zTable/ZTable"
import ContactItem from "features/contact/ContactItem"

// ### TYPES ####################################################################

// ### FUNCTIONS ################################################################

// ### COMPONENT ################################################################

TelephonesToggleListe.defaultProps = {
    open: false,
    toggleable: true,
}

export default function TelephonesToggleListe({
    telephones,
    open,
    toggleable,
}: {
    telephones: Telephone[] | null | undefined
    open?: boolean
    toggleable?: boolean
}) {
    const [isOpen, setIsOpen] = useState(toggleable ? open : true)

    if (!telephones || telephones.length === 0) {
        return <>Aucun Numéro</>
    }

    return (
        <div className="row col-12 p-1 m-4 zzAutoScrollX">
            {toggleable && (
                <div
                    className="btn btn-xs btn-outline-primary"
                    onClick={() => setIsOpen(!isOpen)}
                    aria-hidden="true"
                >
                    {isOpen ? "Masquer " : "Afficher "}
                    {telephones.length} numéro
                    {telephones.length > 1 && "s"}
                </div>
            )}
            {isOpen && (
                <ul>
                    {telephones.map((tel) => (
                        <li key={"tel-" + tel.id}>
                            {tel.numero}
                            {tel.preferentiel && (
                                <span title="Préférentiel"> ★</span>
                            )}
                            {tel.genre && <small> ({tel.genre})</small>}

                            {tel.commentaire ? (
                                <small>
                                    <i>
                                        <br />
                                        {`"${tel.commentaire}"`}
                                    </i>
                                </small>
                            ) : (
                                ""
                            )}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    )
}
