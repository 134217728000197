/* eslint-disable react/destructuring-assignment */
// ### IMPORTS ##################################################################

import React from "react"
import { NavLink } from "react-router-dom"
import { useAppSelector } from "store/store"
// import { Secteur } from "types"
import { IcoFlag } from "libs/zIcones"

// ### TYPES ####################################################################

SecteurNavlinkAndFlag.defaultProps = {
    asNavLink: true,
}

// ### FUNCTIONS ################################################################

// ### COMPONENT ################################################################

export default function SecteurNavlinkAndFlag({
    id,
    asNavLink,
}: {
    id: number | null | undefined
    asNavLink?: boolean
}) {
    if (!id) {
        return null
    }

    const { gSecteurs } = useAppSelector((state) => state.data)
    const secteur = gSecteurs?.find((sec) => sec.id === id)

    if (!secteur) {
        return <>Secteur Inconnu : {id}</>
    }

    return (
        <>
            <IcoFlag style={{ color: secteur.couleur }} />{" "}
            {asNavLink ? (
                <NavLink to={"/secteurs/" + secteur.id}>{secteur.nom}</NavLink>
            ) : (
                secteur.nom
            )}
        </>
    )
}
