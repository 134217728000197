// ### IMPORTS ##################################################################

import React from "react"
import { ZPageSectionHelp, ZPageSectionTodo } from "libs/zPage"

// ### TYPES ####################################################################

// ### FUNCTIONS ################################################################

// ### COMPONENT ################################################################

export default function ContactsQuestions() {
    return (
        <>
            <ZPageSectionHelp className="col-md-6 col-12">
                <h2>Help:</h2>
                <p>
                    Sur cette page vous trouverez toutes les infos sur les
                    contacts.
                    <br />
                    <br />
                    Contacts = Clients + Personnels des exploitations + ...
                    <br />
                </p>
            </ZPageSectionHelp>

            <ZPageSectionTodo className="col-md-6 col-12">
                <h2>Todo:</h2>
                <ul>
                    <li>Editer la liste des contacts</li>
                    <li>
                        Rechercher un contact (par nom ou par exploitations)
                    </li>
                    <li>Filtrer les contacts (agents, exploitants...)</li>
                    <li>
                        Cliquez sur un numéro pour appeler ou ajouter aux
                        contacts du tél
                    </li>
                </ul>
            </ZPageSectionTodo>
        </>
    )
}
