// ### DOCUMENTATION ############################################################

// ### IMPORTS ##################################################################

import React from "react"
import styled from "styled-components"
import { useParams } from "react-router-dom"
import { Contact } from "types"
// import { contacts } from "utils/data-exploitations"
import {
    ZPage,
    ZPageHeader,
    ZPageContent,
    ZPageSection,
    // ZPageSectionHelp,
    ZPageSectionTodo,
} from "libs/zPage"

// ### TYPES ####################################################################

// ### FUNCTIONS ################################################################

// ### COMPONENT ################################################################

export default function PageContatShow() {
    const contactId = Number(useParams().id) || 0
    if (contactId <= 0) {
        return <h1 className="text-danger">Erreur url</h1>
    }

    const contacts: Contact[] = [] // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

    const contact: Contact | undefined = contacts.find(
        (ex) => ex.id === contactId
    )

    if (!contact) {
        return null
    }

    return (
        <ZPage documentTitle={contact.nom + " " + contact.prenom}>
            <ZPageHeader>
                <h1>
                    Contact :{" "}
                    <i>
                        <b>{contact.nom}</b> {contact.prenom}
                    </i>{" "}
                    ({contact.fonction})
                </h1>{" "}
            </ZPageHeader>
            <ZPageContent className="row col-12">
                {/* 
                <ZPageSectionHelp className="col-md-6 col-12">
                    <h2>Help:</h2>
                    <p>
                        Sur cette page vous trouverez toutes les infos sur le
                        contact séléctionné.
                        <br />
                        <br />
                        Contact = Client, Personnel des exploitations, Agent de
                        remplacements, Personnel de votre agence, ...
                    </p>
                </ZPageSectionHelp>
				*/}
                <ZPageSection>
                    <StyledContact key={"contact" + contact.id}>
                        <b>{contact.nom}</b> {contact.prenom}
                        <span> ({contact.fonction})</span>
                        <ul>
                            {contact.telephones?.map((tel) => (
                                <li key={"tel" + contact.id + "-" + tel.id}>
                                    {" "}
                                    <div>
                                        <b>{tel.numero}</b> ({tel.genre})
                                        {tel.preferentiel ? (
                                            <small>
                                                -preferentiel-
                                                <br />
                                            </small>
                                        ) : (
                                            ""
                                        )}
                                        {tel.commentaire ? (
                                            <i>{`"${tel.commentaire}"`}</i>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </StyledContact>
                </ZPageSection>
                <ZPageSectionTodo className="col-md-6 col-12">
                    <h2>Todo:</h2>
                    <ul>
                        <li>
                            Modifier le contact (nom, fonction, adresse,
                            téléphone)
                        </li>
                        <li>
                            Cliquez sur un numéro pour appeler ou ajouter aux
                            contacts du tél
                        </li>
                        <li>Afficher l'addresse sur google map</li>
                        <li>Afficher les exploitations de ce contact</li>
                    </ul>
                </ZPageSectionTodo>
            </ZPageContent>
        </ZPage>
    )
}

// ### STYLES ###################################################################

const StyledContact = styled.div`
    /*color: $C1d1 !important;*/
    // background-color: rgb(200, 200, 200);
    ul {
        font-size: 0.8em;
        li {
            margin-bottom: 0.5em;
        }
    }
    margin-bottom: 0.5em; ;
`
