/* eslint-disable no-unused-vars */ // !!!!!!!!!!!!!!!!!!!!
// ### DOCUMENTATION ############################################################

// ### IMPORTS ##################################################################

import React, { useState } from "react"
import { Button } from "react-bootstrap"
import styled from "styled-components"

import { useAppSelector, useAppDispatch } from "store/store"
import { setSecteurs } from "store/dataReducer"

import SecteursTable from "features/secteur/SecteursTable"
// import { Secteur } from "types"

import { NavLink } from "react-router-dom"
import {
    ZPage,
    ZPageHeader,
    ZPageContent,
    ZPageSection,
    // ZPageSectionHelp,
    ZPageSectionTodo,
} from "libs/zPage"
import { apiFetchSecteurs } from "utils/api"
import { IcoMap } from "libs/zIcones"

// ### TYPES ####################################################################

// ### FUNCTIONS ################################################################

// ### COMPONENT ################################################################

export default function PageSecteurs() {
    const dispatch = useAppDispatch()
    const { secteurs } = useAppSelector((state) => state.data)
    const [showTable, setShowTable] = useState(true)

    return (
        <ZPage
            documentTitle="Secteurs"
            fetchFunction={() => apiFetchSecteurs()}
            fetchSuccessFunction={(result: any) =>
                dispatch(setSecteurs(result.secteurs))
            }
        >
            <ZPageHeader>
                <h1>
                    <IcoMap /> Listes des Secteurs
                </h1>
                <p>
                    Sur cette page, vous <del>pouvez</del> pourrez éditer les
                    secteurs et leur attribuer des agents.
                </p>
                <p className="text-danger">
                    Cette page <del>est</del> sera réservée au admin !
                </p>
            </ZPageHeader>
            <ZPageContent className="row col-12">
                {secteurs && (
                    <ZPageSection>
                        <Button
                            className="btn-sm"
                            onClick={() => setShowTable(!showTable)}
                        >
                            Affichage
                        </Button>
                        {showTable ? (
                            <SecteursTable secteurs={secteurs} />
                        ) : (
                            <>
                                {secteurs.map((exp) => (
                                    <StyledItemSecteur
                                        to={"/secteur/" + exp.id}
                                        key={"exp-" + exp.id}
                                        className="border border-primary"
                                    >
                                        {exp.nom}
                                    </StyledItemSecteur>
                                ))}
                            </>
                        )}
                    </ZPageSection>
                )}

                <ZPageSectionTodo className="col-md-6 col-12">
                    <h2>Todo:</h2>
                    <ul>
                        <li>Ajouter / Modifier des Secteurs</li>
                    </ul>
                </ZPageSectionTodo>
            </ZPageContent>
        </ZPage>
    )
}
// ### STYLES ###################################################################

const StyledItemSecteur = styled(NavLink)`
    margin: 0.4em;
    margin-bottom: 0.8em;
    padding: 0.4em;
    display: block;
    text-decoration: none;
    &:hover {
        background-color: #e3ffd7;
    }
`
