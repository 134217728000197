/* eslint-disable no-unused-vars */ // !!!!!!!!!!!!!!
// ### DOCUMENTATION ############################################################

// ### IMPORTS ##################################################################

import React from "react"
import ReactMarkdown from "react-markdown"
import { ZPageSection } from "libs/zPage"
import { Exploitation } from "types"

// ### TYPES ####################################################################

// ### FUNCTIONS ################################################################

// ### COMPONENT ################################################################

export default function ExploitationSectionNotes({
    exploitation,
}: {
    exploitation: Exploitation
}) {
    return (
        <>
            <h2>Consignes & Notes</h2>
            {exploitation.notes?.map((note) => (
                <ZPageSection
                    key={"note" + note.id}
                    className="col-md-6 col-12"
                >
                    <h4>{note.title}</h4>
                    <div>
                        <ReactMarkdown>{note.text}</ReactMarkdown>
                    </div>
                    <div>crée par xxxxxxxxx le {note.createdAt}</div>
                    {note.updatedAt && (
                        <div>modifiée par xxxxxxxxx le {note.updatedAt}</div>
                    )}
                </ZPageSection>
            ))}
            <ZPageSection className="col-md-6 col-12">
                <h4>+ Ajouter une note</h4>
            </ZPageSection>
        </>
    )
}

// ### STYLES ###################################################################
