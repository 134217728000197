// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import styled from "styled-components"
import { theme } from "styles/theme"
import ZFormButtonSubmit from "./ZFormButtonSubmit"
import ZFormButtonCancel from "./ZFormButtonCancel"
import { FormContext } from "./ZForm"

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

type Props = {
    closeForm: any
}

export default function ZFormFooter({ closeForm }: Props) {
    return (
        <FormContext.Consumer>
            {({ isSubmitting }) => (
                <StyledFooter className="FOOTTER col-12 mt-2">
                    {!isSubmitting ? (
                        <>
                            <ZFormButtonSubmit />
                            <ZFormButtonCancel handleCancel={closeForm} />
                        </>
                    ) : (
                        <h2>Loading</h2>
                    )}
                </StyledFooter>
            )}
        </FormContext.Consumer>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS

/**
 *  La page entiere (headerMenu + date/heure + Filters + Edt)
 */
const StyledFooter = styled.div`
    margin-top: 20px;
    padding-top: 10px;
    border-top: 1px solid ${theme.colors.c1};
`
