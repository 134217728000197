/* eslint-disable jsx-a11y/no-autofocus */
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import Form from "react-bootstrap/Form"
import FloatingLabel from "react-bootstrap/FloatingLabel"

// import styled from "styled-components"

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

interface ISelectOption {
    value: any
    text: string
}

type Props = {
    name: string
    label: string
    // type: string
    hint?: React.ReactNode | null
    autoFocus?: boolean
    fieldClassName?: string | null
    inputClassName?: string | null
    value: any
    handleInputChange: (_ev: any) => void
    error: React.ReactNode | null
    fieldStyle?: "BS_FLOATING_LABEL" | "SIMPLE"
    selectOptions: ISelectOption[]
    // requiered // todo
}

ZFieldSelect.defaultProps = {
    hint: null,
    autoFocus: false,
    fieldClassName: null,
    inputClassName: null,
    fieldStyle: "BS_FLOATING_LABEL",
}

export default function ZFieldSelect({
    name,
    label,
    // type,
    hint,
    autoFocus,
    fieldClassName,
    inputClassName,
    value,
    handleInputChange,
    error,
    fieldStyle,
    selectOptions,
}: Props) {
    if (fieldStyle === "BS_FLOATING_LABEL") {
        return (
            <FloatingLabel
                controlId={name}
                label={label}
                className={fieldClassName + " mb-3   "}
                // style={type === "checkbox" ? { left: "20px" } : {}}
            >
                <Form.Select
                    className={inputClassName + " "}
                    autoFocus={autoFocus}
                    name={name}
                    value={value}
                    onChange={handleInputChange}
                >
                    {selectOptions.map((op: ISelectOption) => (
                        <option
                            key={"option-" + name + "-" + op.text}
                            value={op.value}
                        >
                            {op.text}
                        </option>
                    ))}
                </Form.Select>
                {hint && <div className="text-info">{hint}</div>}
                {error && <div className="text-danger">{error}</div>}
            </FloatingLabel>
        )
    }
    console.log("fieldClassName", name + " - " + fieldClassName)
    return (
        <fieldset
            // controlId={name}
            // label={label}
            className={fieldClassName + " mb-3  "}
            // style={type === "checkbox" ? { left: "20px" } : {}}
        >
            <label htmlFor={"form-label-" + name}>{label}</label>
            <select
                className={inputClassName + " "}
                autoFocus={autoFocus}
                name={name}
                value={value}
                onChange={handleInputChange}
            >
                {selectOptions.map((op: any) => (
                    <option
                        key={"option-" + name + "-" + op.text}
                        value={op.value}
                    >
                        {op.text}
                    </option>
                ))}
            </select>
            {hint && <div className="text-info">{hint}</div>}
            {error && <div className="text-danger">{error}</div>}
        </fieldset>
    )
}
