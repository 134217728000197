// ### DOCUMENTATION ############################################################
/*
 *  ZPage = ZPageMenu + ZPageHeader + ZPageContent
 *
 *
 *
 *
 */

// ### IMPORTS ##################################################################
import React, { useState, useEffect } from "react"
import { ZErrorInterface, ZError } from "libs/zError"
import styled from "styled-components"
import { theme } from "styles/theme"
// import NavMenu from "ui/navMenu/NavMenu"
import Loading from "ui/Loading"
import zedConfig from "../../zedConfig"

// ### TYPES ####################################################################

type Props = {
    documentTitle: string
    children?: React.ReactNode
    className?: String | null
    // * option 1:
    fetchFunction?: (() => any) | null
    fetchSuccessFunction?: ((_result: any) => any) | null
    // * option 2:
    isLoading?: boolean
    error?: String | null
}

ZPage.defaultProps = {
    children: null,
    className: "",
    fetchFunction: null,
    fetchSuccessFunction: null,
    isLoading: false,
    error: null,
}

// ### COMPONENT ################################################################

export default function ZPage({
    children,
    className,
    documentTitle,
    fetchFunction,
    fetchSuccessFunction,
    isLoading,
    error,
}: Props) {
    const [isFetching, setIsFetching] = useState(false)
    const [fetchError, setFetchError] = useState<String | null>(error || null)
    const [zError, setZError] = useState<ZErrorInterface | null>(null)

    useEffect(() => {
        document.title =
            zedConfig.zedPage.documentTitlePrefix +
            (documentTitle === "" ? "" : ": " + documentTitle)
        setFetchError(null)
        if (fetchFunction) {
            setIsFetching(true)
            fetchFunction().then((response: any) => {
                if (response.zError) {
                    setZError(response.zError)
                } else if (response.error) {
                    setFetchError(response.error)
                } else if (fetchSuccessFunction) {
                    fetchSuccessFunction(response)
                }
                setIsFetching(false)
            })
        }
    }, [])

    useEffect(() => {
        document.title =
            zedConfig.zedPage.documentTitlePrefix +
            (documentTitle === "" ? "" : ": " + documentTitle)
    }, [documentTitle])

    return (
        <StyledZPage id="zPage" className={className + " col-12"}>
            {isFetching && <Loading />}
            {isLoading && <Loading />}
            {fetchError && <h3>Erreur: {fetchError}</h3>}
            {zError && <ZError zError={zError} />}
            {error && <h3>Erreur: {error}</h3>}
            {!isFetching &&
                !isLoading &&
                !fetchError &&
                !zError &&
                !error &&
                children}
        </StyledZPage>
    )
}

// ### STYLES ###################################################################

const StyledZPage = styled.div`
    /*
    // background-color: ${theme.colors.c1l4};
    // background-color: lime;
    // height: 100%;
    // min-height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0px !important;
    width: 100% !important;
    .btn {
        cursor: pointer;
    }
    .row {
        margin: 0px !important;
    }
    padding-top: 270px;
	*/
`
