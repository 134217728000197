/* eslint-disable no-unused-vars */ // !!!!!!!!!!!!!!!!!!!
// ### IMPORTS ##################################################################

import React from "react"
import { NavLink } from "react-router-dom"
import { Contact, Exploitation } from "types"
import ZTable from "libs/zTable/ZTable"
import ContactItem from "features/contact/ContactItem"
import TelephonesToggleListe from "features/telephone/TelephonesToggleListe"

// ### TYPES ####################################################################

// ### FUNCTIONS ################################################################

function NavLinkContactShow(contactId: number, text: string) {
    return <NavLink to={"/contact/" + contactId}>{text}</NavLink>
}

function showTelephones(contact: Contact) {
    return <TelephonesToggleListe telephones={contact.telephones} />
}

function showExploitation(contact: Contact) {
    return (
        <ul>
            {contact.contactExploitations?.map((ce) => (
                <li key={"ce" + ce.id}>
                    {ce.exploitation.nom}
                    {ce.fonction && <small> ({ce.fonction})</small>}
                </li>
            ))}
        </ul>
    )
}
function showSecteurs(contact: Contact) {
    return (
        <ul>
            {contact.secteurs
                // ?.filter((rr) => rr !== "ROLE_USER")
                ?.map((sect) => (
                    <li key={"user-" + contact.id + "-sect-" + sect.id}>
                        {sect.nom}
                    </li>
                ))}
        </ul>
    )
}

// ### COMPONENT ################################################################

export default function ContactsTable({ contacts }: { contacts: Contact[] }) {
    //  const filterdSeries = series.filter((ser) => ser.statusCouple === "watch")
    // const filterdSeries = series.filter(() => true)
    // console.log("filterdSeries")
    // console.log(filterdSeries)
    const tableColumns = [
        {
            name: "id",
            text: "id",
        },
        {
            name: "nom",
            text: "nom",
            cellHtml: (contact: any) =>
                NavLinkContactShow(contact.id, contact.nom),
        },
        {
            name: "prenom",
            text: "prenom",
            cellHtml: (contact: any) =>
                NavLinkContactShow(contact.id, contact.prenom),
        },
        {
            name: "exploitations",
            text: "exploitations",
            cellHtml: (contact: any) => showExploitation(contact),
            sortValue: (contact: any) => contact.contactExploitations.length,
        },
        {
            name: "adresse",
            text: "adresse",
        },
        {
            name: "telephones",
            text: "telephones",
            cellHtml: (contact: any) => showTelephones(contact),
            sortValue: (contact: any) => contact.telephones.length,
        },
        /*
        {
            name: "telephones2",
            text: "telephones2",
            cellHtml: (contact: any) => (
               
            ),
            sortValue: (contact: any) => contact.telephones.length,
        },
*/
        {
            name: "secteurs",
            text: "Secteurs",
            cellHtml: (contact: any) => showSecteurs(contact),
            sortValue: (exploitation: any) =>
                exploitation.secteur?.nom || "zzz",
        },
    ]

    return (
        <div className="row col-12 p-1 m-4 zzAutoScrollX">
            <ZTable
                columns={tableColumns}
                data={contacts}
                className="table table-bordered table-sm table-striped table-hover bg-light "
            />
        </div>
    )
}
