/* eslint-disable no-unused-vars */ // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
import React from "react"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import { useAppSelector } from "store/store"
import "bootstrap/dist/css/bootstrap.min.css"
import "styles/zPage.scss"
import "styles/global.scss"

import HeaderMenu from "ui/headerMenu/HeaderMenu"

import PageHome from "pages/PageHome"

import PageAuthRegister from "pages/auth/PageAuthRegister"
import PageAuthRegisterValidation from "pages/auth/PageAuthRegisterValidation"
import PageAuthLogin from "pages/auth/PageAuthLogin"

import PageExploitations from "pages/exploitation/PageExploitations"
import PageExploitationShow from "pages/exploitation/PageExploitationShow"
import PageExploitationsSearch from "pages/exploitation/PageExploitationsSearch"

import PageUsers from "pages/PageUsers"
import PageContats from "pages/PageContats"
import PageContatShow from "pages/PageContatShow"
import PageSecteurs from "pages/PageSecteurs"

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

export default function App() {
    console.log("■■■■■ RENDER ■ App")

    const { authIsConnected } = useAppSelector((state) => state.auth)
    const auth = useAppSelector((state) => state.auth)

    if (!authIsConnected) {
        return (
            <Router>
                <Routes>
                    <Route
                        path="/auth/register"
                        element={<PageAuthRegister />}
                    />
                    <Route
                        path="/auth/register/validation/:email/:token"
                        element={<PageAuthRegisterValidation />}
                    />
                    <Route path="/auth/login" element={<PageAuthLogin />} />
                    <Route path="*" element={<PageAuthLogin />} />
                </Routes>
            </Router>
        )
    }

    return (
        <Router>
            <HeaderMenu />
            <Routes>
                <Route
                    path="exploitations/search"
                    element={<PageExploitationsSearch />}
                />
                <Route
                    path="exploitation/:id"
                    element={<PageExploitationShow />}
                />
                <Route
                    path="admin/exploitations"
                    element={<PageExploitations />}
                />
                <Route path="admin/secteurs" element={<PageSecteurs />} />
                <Route path="admin/users" element={<PageUsers />} />
                <Route path="contact/:id" element={<PageContatShow />} />
                <Route path="admin/contacts" element={<PageContats />} />
                <Route path="*" element={<PageHome />} />
            </Routes>
        </Router>
    )
}

// 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓	STYLED_COMPONENTS
