// ### IMPORTS ##################################################################

import { zedFetch } from "libs/zedFetch"
import { Exploitation, User } from "types"
import { setGlobal } from "store/dataReducer"
// import { useAppDispatch } from "store/store"

// ### FUNCTIONS ################################################################

export async function globalFetch(
    setIsLoading: (_bool: boolean) => any,
    setZError: (_xx: any) => any,
    dispatch: (_xx: any) => any
) {
    // const dispatchx = useAppDispatch()
    setIsLoading(true)
    apiFetchGlobal().then((response: any) => {
        if (response.zError) {
            setZError(response.zError)
        } else {
            dispatch(setGlobal(response))
        }
        setIsLoading(false)
    })
}

export async function apiFetchGlobal() {
    return zedFetch({
        shortUrl: "/users/global",
        method: "GET",
        requierdFields: [
            "exploitations",
            "secteurs",
            "contacts",
            "users",
            "exploitationUsers",
        ],
    })
}

// **** EXPOLITATIONS ****************

export async function apiFetchExploitations() {
    return zedFetch({
        shortUrl: "/exploitations",
        method: "GET",
        requierdFields: ["exploitations"],
    })
}

export async function apiFetchExploitation(exploitationId: number) {
    return zedFetch({
        shortUrl: "/exploitations/" + exploitationId,
        method: "GET",
        requierdFields: ["exploitation"],
    })
}

export async function zApiExploitationCreate(exploitation: Exploitation) {
    return zedFetch({
        shortUrl: "/exploitations/new",
        method: "POST",
        body: exploitation,
        requierdFields: ["exploitation"],
    })
}

export async function zApiExploitationUpdate(exploitation: Exploitation) {
    return zedFetch({
        shortUrl: "/exploitations/" + exploitation.id + "/edit",
        method: "POST",
        body: exploitation,
        requierdFields: ["exploitation"],
    })
}

export async function zApiExploitationDelete(exploitation: Exploitation) {
    console.log("user", exploitation)
    return zedFetch({
        shortUrl: "/exploitations/" + exploitation.id + "/delete",
        method: "DELETE",
        requierdFields: ["SUCCESSFULLY_DELETED"],
    })
}

// **** EXPLOITATION_USERS // FAVORITE // MesExploitations ****************

export async function apiFetchMesExploitations() {
    return zedFetch({
        shortUrl: "/exploitations/mesexploitations",
        method: "GET",
        requierdFields: ["exploitationUsers"],
    })
}

export async function zApiExploitationAddToMyFavorite(exploitationId: number) {
    return zedFetch({
        shortUrl: "/exploitations/" + exploitationId + "/add-to-my-favorite",
        method: "POST",
        requierdFields: ["exploitationUser"],
    })
}

export async function zApiExploitationRemoveFromMyFavorite(
    exploitationId: number
) {
    return zedFetch({
        shortUrl:
            "/exploitations/" + exploitationId + "/remove-from-my-favorite",
        method: "DELETE",
        requierdFields: ["SUCCESSFULLY_DELETED"],
    })
}

// **** USERS ****************

export async function apiFetchUsers() {
    return zedFetch({
        shortUrl: "/users",
        method: "GET",
        requierdFields: ["users"],
    })
}

export async function zApiUserCreate(user: User) {
    console.log(user)
    return zedFetch({
        shortUrl: "/users/new",
        method: "POST",
        body: user,
        requierdFields: ["user"],
    })
}

export async function zApiUserUpdate(user: User) {
    console.log(user)
    return zedFetch({
        shortUrl: "/users/" + user.id + "/edit",
        method: "POST",
        body: user,
        requierdFields: ["user"],
    })
}

export async function zApiUserDelete(user: User) {
    console.log("user", user)
    return zedFetch({
        shortUrl: "/users/" + user.id + "/delete",
        method: "DELETE",
        requierdFields: ["SUCCESSFULLY_DELETED"],
    })
}

// **** SECTEURS ****************

export async function apiFetchSecteurs() {
    return zedFetch({
        shortUrl: "/secteurs",
        method: "GET",
        requierdFields: ["secteurs"],
    })
}

export async function zApiSecteurCreate(user: User) {
    console.log(user)
    return zedFetch({
        shortUrl: "/secteurs/new",
        method: "POST",
        body: user,
        requierdFields: ["secteur"],
    })
}

export async function zApiSecteurUpdate(user: User) {
    console.log(user)
    return zedFetch({
        shortUrl: "/secteurs/" + user.id + "/edit",
        method: "POST",
        body: user,
        requierdFields: ["secteur"],
    })
}

export async function zApiSecteurDelete(user: User) {
    console.log("user", user)
    return zedFetch({
        shortUrl: "/secteurs/" + user.id + "/delete",
        method: "DELETE",
        requierdFields: ["SUCCESSFULLY_DELETED"],
    })
}

// **** CONTACTS ****************

export async function apiFetchContacts() {
    return zedFetch({
        shortUrl: "/contacts",
        method: "GET",
        requierdFields: ["contacts"],
    })
}
